import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SearchIcon from "../../../assets/images/search-icon.png";
import AddIcon from "../../../assets/images/add-icon.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalCloseBtn from "../../../assets/images/modal-close-btn.png";
import UserIconGreen from "../../../assets/images/user-icon-primary.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/loader/loader";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/delete.png";
import DotIcon from "../../../assets/images/dots-horizontal-icon.png";
import Pagination from "../../common/pagination/pagination";

const OwnerList = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    phone_number: "",
    password: "",
    bio: "",
  });
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const addNewUser = () => {
    setEditingUser(null);
    setUserData({
      username: "",
      email: "",
      phone_number: "",
      password: "",
      confirmPassword: "",
      bio: "",
    });
    setModalShow(true);
  };
  const addUser = async () => {
    if (userData.password !== userData.confirmPassword) {
      setError("Password and confirm password must match");
      return;
    }
    setSpinner(true);
    try {
      const token = localStorage.token;
      await axios.post(`${BASE_URL}admin/add-owner`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Add owner successfully!");
      setSpinner(false);
      fetchUsers();
      setUserData({
        username: "",
        email: "",
        phone_number: "",
        password: "",
        confirmPassword: "",
        bio: "",
      });
      setError(null);
      setModalShow(false);
    } catch (error) {
      const errorContent = error.response.data.message;
      setError(errorContent);
      setSpinner(false);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPageNumber(1);
  };
  useEffect(() => {
    fetchUsers(searchQuery);
  }, [pageNumber, searchQuery]);
  const fetchUsers = async (query = "") => {
    setSpinner(true);
    try {
      const token = localStorage.token;
      const response = await axios.get(`${BASE_URL}admin/get-owner`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber,
          search: query,
        },
      });
      setUsers(response.data.data.users.data);
      setTotalPages(response.data.data.users.last_page);
      setSpinner(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setSpinner(false);
    }
  };
  const handleDelete = async (id) => {
    setSpinner(true);
    try {
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}admin/delete_user`,
        {
          user_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSpinner(false);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting post:", error);
      setSpinner(false);
    }
  };
  const handleEdit = async (id) => {
    try {
      const token = localStorage.token;
      const response = await axios.get(
        `${BASE_URL}admin/user_details?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = response?.data?.data?.user;
      const userFilterData = userData;
      setEditingUser(userFilterData);
      setUserData({
        username: userFilterData.username,
        email: userFilterData.email,
        phone_number: userFilterData.phone_number,
        password: "",
        confirmPassword: "",
        bio: userFilterData.bio,
      });
      setModalShow(true);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const updateUser = async () => {
    if (userData.password !== userData.confirmPassword) {
      setError("Password and confirm password must match");
      return;
    }
    setSpinner(true);
    try {
      const token = localStorage.token;
      const requestData = {
        user_id: editingUser.id,
        ...userData,
      };
      await axios.post(`${BASE_URL}admin/update_user`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("User updated successfully!");
      setSpinner(false);
      fetchUsers();
      setEditingUser(null);
      setUserData({
        id: "",
        username: "",
        email: "",
        phone_number: "",
        password: "",
        bio: "",
      });
      setError(null);
      setModalShow(false);
    } catch (error) {
      const errorContent = error.response.data.message;
      setError(errorContent);
      setSpinner(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="common-bg">
        <div className="container-fluid">
          <div className="common-section">
            <div className="custom-table-header ">
              <h1>List of all Owners</h1>
            </div>
            <div className="filter-wrapper mt-4 mb-3">
              <div className="filter-wrapper-left">
                <div className="search-field">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <img src={SearchIcon} alt="" />
                </div>
              </div>
              <div className="filter-wrapper-right">
                <div className="filter-button-wrapper">
                  <button className="add-user" onClick={addNewUser}>
                    <img src={AddIcon} alt="" /> Add New Owner
                  </button>
                </div>
              </div>
            </div>
            <div className="table-body">
              <div className="custom-table ">
                <div className="table-header-row">
                  <div className="table-cell">Name</div>
                  <div className="table-cell">Email</div>
                  <div className="table-cell">Phone</div>
                  <div className="table-cell">Bio</div>
                  <div className="table-cell"></div>
                </div>

                {users && users.length > 0 ? (
                  users.map((user, index) => (
                    <div className="table-row" key={index}>
                      <div className="table-cell" data-heading="Name:">
                        <div className="table-cell-inner">
                          {user?.username || "--"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Email:">
                        <div className="table-cell-inner">
                          {user?.email || "--"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Phone:">
                        <div className="table-cell-inner">
                          {user?.phone_number || "--"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Phone:">
                        <div className="table-cell-inner">
                          {user?.bio || "--"}
                        </div>
                      </div>
                      <div
                        className="table-cell action-dropdown"
                        data-heading="Action:"
                      >
                        <div className="table-cell-inner ">
                          <img
                            className="table-action-btn"
                            src={DotIcon}
                            alt="dots"
                            onClick={() => toggleDropdown(user.id)}
                          />
                        </div>
                        {activeDropdown === user.id && (
                          <div
                            ref={dropdownRef}
                            className="table-action-dropdown"
                          >
                            <button onClick={() => handleEdit(user.id)}>
                              Edit
                            </button>
                            <button onClick={() => handleDelete(user.id)}>
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data-message">No data available</div>
                )}
              </div>
            </div>
            {totalPageCount > 1 && (
              <Pagination
                totalPageCount={totalPageCount}
                currentPage={pageNumber}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      {/* modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modal-header pb-0">
          <Button
            className="modal-close-button"
            onClick={() => setModalShow(false)}
          >
            <img src={ModalCloseBtn} alt="image" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="moda-body-content">
            <div className="modal-content-header">
              <img src={UserIconGreen} alt="image" />
              <h1>{editingUser ? "Update Owner" : "Add New Owners"}</h1>
              {!editingUser && (
                <p>
                  Please fill the Information to <strong>Add</strong> New{" "}
                  <strong>Owner</strong>.
                </p>
              )}
            </div>
            <form>
              {error && (
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="mb-3 modal-form-field">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name Here"
                  name="username"
                  value={userData.username || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 modal-form-field">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email Here"
                  name="email"
                  value={userData.email || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 modal-form-field">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone Here"
                  name="phone_number"
                  value={userData.phone_number || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 modal-form-field">
                <label>Pasword</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Password Here"
                  name="password"
                  value={userData.password || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 modal-form-field">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Your Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={userData.confirmPassword || ""}
                />
              </div>
              <div className="mb-3 modal-form-field">
                <label>Bio</label>
                <textarea
                  placeholder="Enter Bio Here"
                  className="form-control"
                  onChange={handleChange}
                  name="bio"
                  value={userData.bio || ""}
                ></textarea>
              </div>
              <div className="mb-3 modal-form-field-btn">
                <button type="button" onClick={() => setModalShow(false)}>
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={editingUser ? updateUser : addUser}
                >
                  {" "}
                  {editingUser ? "Update Owner" : "Add New Owner"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OwnerList;
