import React, { useState } from "react";
import Logo from "../../../assets/images/logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosPostCall } from "../../../api/auth/auth";
import { ToastContainer, toast } from "react-toastify";

const OPT = () => {
  const location = useLocation();
  const [password, setPassword] = useState({ password: "" });
  const [error, setError] = useState(null);
  let navigation = useNavigate();
  const userchange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.password !== password.confirmPassword) {
      setError("Password and confirm password must match");
      return;
    }
    try {
      const endpoint = "reset-password";
      await axiosPostCall(endpoint, {
        ...password,
        email: location.state.email,
        pin_code: location.state.pin_code,
      });
      toast.success("Password Reset!", {
        onClose: () => navigation("/"),
      });
      setError(null);
    } catch (error) {
      const errorContent = error.response.data.message;
      console.log(error);
      setError(errorContent);
    }
  };
  return (
    <div className="container-fluid auth-section">
      <div className="auth-section-banner">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="auth-section-banner-content">
          <h1>Enter a New Password for login to Space Management</h1>
          <p>
            Enter your Email and Password for login to Space Management Enter
            your Email and Password for login to Space ManagementEnter your
            Email and Password for login to Space Management
          </p>
        </div>
      </div>
      <div className="auth-section-form">
        <div className="auth-section-form-content">
          <div className="auth-logo">
            <img src={Logo} alt="logo" />
            <p>A link to reset your password will be sent to your email</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Set New Password</h1>
              </div>
            </div>
            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="mb-4 form-field ">
              <input
                type="password"
                className="form-control"
                placeholder="Enter New Password"
                name="password"
                onChange={userchange}
                value={password.password}
              />
            </div>
            <div className="mb-4 form-field ">
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                name="confirmPassword"
                onChange={userchange}
                value={password.confirmPassword}
              />
            </div>
            <div className="form-field mb-4">
              <button type="submit">Save Passowrd</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OPT;
