import React from "react";
import BuildingImage from "../../../assets/images/landingImages/building-img.png";
import BuildingFrame from "../../../assets/images/landingImages/image-frame.png";
import BuildingIcon from "../../../assets/images/landingImages/building-icon.png";
import LocationIcon from "../../../assets/images/landingImages/location-icon.png";
import { NavLink } from "react-bootstrap";
const BuildingSpace = () => {
  return (
    <div className="container building-space-section">
      <div className="building-img-container">
        <img className="building-frame" src={BuildingFrame} alt="img" />
        <img className="building-icon" src={BuildingIcon} alt="img" />
        <img src={BuildingImage} alt="img" />
        <div className="building-space-card">
          <div className="building-space-card-header">
            <h1>
              Building Space <span>23 Buildings</span>
            </h1>
          </div>
          <div className="building-space-card-footer">
            <img src={LocationIcon} alt="img" />
            <h1>
              Click Here <NavLink>Click on link to See Map</NavLink>
            </h1>
          </div>
        </div>
      </div>
      <div className="building-content-container">
        <h1>The Easiest Method to find a Building Space</h1>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
        <NavLink>Read More</NavLink>
      </div>
    </div>
  );
};

export default BuildingSpace;
