import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = {
  loading: false,
  buildingGraph: [],
  error: "",
};

export const fetchBuildingGraph = createAsyncThunk(
  "buildingGraph/fetchBuildingGraph",
  async (id) => {
    const token = localStorage.token;
    const response = await axios.get(
      `${BASE_URL}admin/building_graph_data?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );
    console.log("api response", response);
    return {
      buildingGraph: response.data?.data?.building,
      floorGraph: response.data?.data?.floor,
    };
  }
);

const buildingGraphSlice = createSlice({
  name: "buildingGraph",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBuildingGraph.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchBuildingGraph.fulfilled, (state, action) => {
      state.loading = false;
      state.buildingGraph = action.payload.buildingGraph;
      state.floorGraph = action.payload.floorGraph;
      state.error = "";
    });
    builder.addCase(fetchBuildingGraph.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error fetching data";
    });
  },
});

export default buildingGraphSlice.reducer;
