import { Route, Navigate } from "react-router-dom";
import UserBuildingPage from "./pages/user/userBuildingPage";
import UserDashboardPage from "./pages/user/userDashboardPage";
import MyRequestPage from "./pages/user/myRequestPage";
import MySpacePage from "./pages/user/mySpacePage";

const UserRoutes = ({ isAuthenticated, isUser }) => (
  <>
    <Route
      path="/user-building"
      element={
        isAuthenticated && isUser ? <UserBuildingPage /> : <Navigate to="/" />
      }
    />
    <Route
      path="/user-dashboard"
      element={
        isAuthenticated && isUser ? <UserDashboardPage /> : <Navigate to="/" />
      }
    />
    <Route
      path="/my-request"
      element={
        isAuthenticated && isUser ? <MyRequestPage /> : <Navigate to="/" />
      }
    />
    <Route
      path="/my-space"
      element={
        isAuthenticated && isUser ? <MySpacePage /> : <Navigate to="/" />
      }
    />
  </>
);

export default UserRoutes;
