import React, { useState, useEffect } from "react";
import SearchIcon from "../../../assets/images/search-icon.png";
import Loader from "../../common/loader/loader";
import Pagination from "../../common/pagination/pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSpaces,
  setPageNumber,
  setSearchQuery,
} from "../../../slices/spacesSlice";
const MySpace = () => {
  const { spaces, loading, totalPageCount, searchQuery, pageNumber } =
    useSelector((state) => state.spaces);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSpaces({ pageNumber, searchQuery }));
  }, [dispatch, pageNumber, searchQuery]);
  const handleSearchChange = (e) => {
    dispatch(setSearchQuery(e.target.value));
  };
  const handlePageChange = (newPageNumber) => {
    dispatch(setPageNumber(newPageNumber));
  };
  return (
    <>
      {loading && <Loader />}
      <div className="common-bg">
        <div className="container-fluid">
          <div className="common-section">
            <div className="custom-table-header">
              <h1>List of Spaces</h1>
            </div>
            <div className="filter-wrapper mt-4 mb-3">
              <div className="filter-wrapper-left">
                <div className="search-field">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <img src={SearchIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="table-body">
              <div className="custom-table">
                <div className="table-header-row">
                  <div className="table-cell">Owner</div>
                  <div className="table-cell">Building</div>
                  <div className="table-cell">Start Date & Time</div>
                  <div className="table-cell">End Date & Time</div>
                  <div className="table-cell">Floor</div>
                  <div className="table-cell">Space</div>
                </div>
                {spaces && spaces.length > 0 ? (
                  spaces.map((request) => (
                    <div className="table-row">
                      <div className="table-cell" data-heading="User">
                        <div className="table-cell-inner">
                          {request?.building?.owner?.username || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Building">
                        <div className="table-cell-inner">
                          {request?.building?.building_name || "-"}
                        </div>
                      </div>
                      <div
                        className="table-cell"
                        data-heading="Start Date & Time"
                      >
                        <div className="table-cell-inner">
                          {request?.checkin_date || "-"}{" "}
                          {request?.checkin_time || "-"}
                        </div>
                      </div>
                      <div
                        className="table-cell"
                        data-heading="End Date & Time"
                      >
                        <div className="table-cell-inner">
                          {request?.checkout_date || "-"}{" "}
                          {request?.checkout_time || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Floor">
                        <div className="table-cell-inner">
                          {request?.floor?.floor_name || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Space">
                        <div className="table-cell-inner">
                          {request?.space?.space_name || "-"}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data-message">No data available</div>
                )}
              </div>
            </div>
            {totalPageCount > 1 && (
              <Pagination
                totalPageCount={totalPageCount}
                currentPage={pageNumber}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MySpace;
