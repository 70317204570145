import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const BarChart = () => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const roomData = [
    { name: "Room A", occupied: 30 },
    { name: "Room B", occupied: 50 },
    { name: "Room C", occupied: 70 },
    { name: "Room D", occupied: 30 },
    { name: "Room E", occupied: 60 },
  ];

  const handleStartDateChange = (date) => {
    const startOfWeek = moment(date).toDate();
    const endOfWeek = moment(date).add(6, "days").toDate();
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };

  const toggleDatePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  const datePickerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="room-indicator-bar-chart mt-3">
      <div className="utilization-bar-chart-header">
        <div className="graph-heading pb-0">
          <h1 className="m-0">
            <span> Meeting Room Utilization</span>
            Meeting room color indicator
          </h1>
        </div>
        <p>
          Present {moment(startDate).format("MMMM D")} -{" "}
          {moment(endDate).format("MMMM D")}
        </p>
        <div className="range-picker-wrapper">
          <button className="date-toggle" onClick={toggleDatePicker}>
            Date Range
            <KeyboardArrowDownIcon />
          </button>
          {isPickerOpen && (
            <div className="range-picker" ref={datePickerRef}>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsStart
                inline
              />
            </div>
          )}
        </div>
      </div>
      <div className="room-indicator-bar-chart-content mt-3">
        <div className="semi-donut-chart-header">
          <h1>Ground Floor</h1>
          <div className="chart-status">
            <h1>
              <div className="status-box free"></div>Free
            </h1>
            <h1>
              <div className="status-box occupied"></div>Occupied
            </h1>
          </div>
        </div>
        <div className="room-indicator-bar-chart-body">
          {roomData.map((room, index) => {
            const occupiedHeight = room.occupied;
            const freeHeight = 100 - occupiedHeight;

            return (
              <div className="indicator-bar-chart" key={index}>
                <div className="indicator-bar">
                  {freeHeight > 0 && (
                    <div
                      className="free-space"
                      style={{ height: `${freeHeight}%` }}
                    ></div>
                  )}
                  {occupiedHeight > 0 && (
                    <div
                      className="occupied-space"
                      style={{ height: `${occupiedHeight}%` }}
                    ></div>
                  )}
                </div>
                <h1>{room.name}</h1>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BarChart;
