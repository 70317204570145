import React from "react";
import { useBuildingData } from "../../common/useBuildingData";
import Filters from "../../roomUtilization/filters";
import UtilizationIcon1 from "../../../../assets/images/most-utilization-icon.png";
import UtilizationIcon2 from "../../../../assets/images/least-utilization-icon.png";
import RoomInsight from "./roomInsights";
import RoomUtilization from "./roomUtilization";
import RoomGridChart from "./roomGridChart";
const RoomAnalytics = () => {
  const {
    buildingNames,
    floorNames,
    roomNames,
    handleBuildingChange,
    handleFloorChange,
    handleRoomChange,
  } = useBuildingData();

  return (
    <div className="room-analytics-section">
      {" "}
      <Filters
        handleBuildingChange={handleBuildingChange}
        buildingNames={buildingNames}
        handleFloorChange={handleFloorChange}
        floorNames={floorNames}
        handleRoomChange={handleRoomChange}
        roomNames={roomNames}
      />
      <div className="roomUtilization-section mt-3">
        <div className="roomUtilization-col">
          <div className="roomUtilization-card">
            <div className="roomUtilization-card-header">
              <img src={UtilizationIcon2} alt="" />
              <h1>Most Utilized Rooms</h1>
            </div>
            <div className="roomUtilization-card-body">
              <p>Room A1</p>
              <h1>87.60%</h1>
            </div>
          </div>
          <div className="roomUtilization-card">
            <div className="roomUtilization-card-header">
              <img src={UtilizationIcon1} alt="" />
              <h1 className="least-heading">Least Utilized Rooms</h1>
            </div>
            <div className="roomUtilization-card-body">
              <p>Room B1</p>
              <h1>87.60%</h1>
            </div>
          </div>
        </div>
        <RoomUtilization />
      </div>
      <RoomInsight />
      <RoomGridChart />
      <div className="room-map-section mt-3">
        <div className="space-analytics-common-wrapper">
          <div className="space-analytics-header">
            <h1>
              <span>Statistics</span>Space Graphics
            </h1>
          </div>
          <div className="map-box my-2"></div>
          <div className="chart-status ">
            <h1>
              <div className="status-box full-occupied"></div>Fully Occupied
            </h1>

            <h1>
              <div className="status-box full-free"></div>Full Free
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomAnalytics;
