import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/landingImages/logo.png";
import MenuIcon from "../../../assets/images/landingImages/hamburger.png";
import CrossIcon from "../../../assets/images/landingImages/cross.png";
import { NavLink } from "react-router-dom";
const LandingNavbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleOpenMenu = (event) => {
    event.preventDefault();
    setOpenMenu(!openMenu);
  };
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("token");
    if (isAuthenticated) {
      const userAuth = localStorage.getItem("user");
      const authType = JSON.parse(userAuth);
      setIsUser(authType.type === "user");
    }
  }, []);
  return (
    <div
      className={`container-fluid landing-navbar-section ${
        scrolled ? "__scrolled" : ""
      }`}
    >
      <div className="logo-wrapper">
        <NavLink to="/">
          <img src={Logo} alt="logo" />
        </NavLink>
      </div>
      <div className={`__navbarlist ${openMenu ? "open" : ""}`}>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active-menu" : "")}
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink>What we do</NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active-menu" : "")}
              to="/all-building"
            >
              Buildings
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/privacy-policy"
              className={({ isActive }) => (isActive ? "active-menu" : "")}
            >
              Privacy Policy
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/term-condition"
              className={({ isActive }) => (isActive ? "active-menu" : "")}
            >
              Terms & Conditions
            </NavLink>
          </li>
        </ul>
        <div className="__signin">
          <NavLink to={isUser ? "/user-dashboard" : "/user-login"}>
            {isUser ? (
              "Dashboard"
            ) : (
              <>
                <svg
                  width="16"
                  height="18"
                  viewBox="0 0 18 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z"
                    stroke="white"
                    strokeWidth="2"
                  />
                  <path
                    d="M17 16.5C17 18.985 17 21 9 21C1 21 1 18.985 1 16.5C1 14.015 4.582 12 9 12C13.418 12 17 14.015 17 16.5Z"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
                Sign In User
              </>
            )}
          </NavLink>
        </div>
      </div>
      <div className="__menu" onClick={handleOpenMenu}>
        <img src={openMenu ? CrossIcon : MenuIcon} alt="" />
      </div>
    </div>
  );
};

export default LandingNavbar;
