import { Route, Navigate } from "react-router-dom";
import HomePage from "./pages/landingPages/homePage";
import AllbuildingPage from "./pages/landingPages/allbuildingPage";
import BuildingDetailPage from "./pages/landingPages/buildingDetailPage";
import Login from "./pages/admin/auth/login";
import ForgotPassword from "./pages/admin/auth/forgotPassword";
import OPT from "./pages/admin/auth/opt";
import NewPassword from "./pages/admin/auth/newPassword";
import Profile from "./components/common/profile/profile";
import EditProfile from "./components/common/profile/editProfile";
import PrivacyPolicy from "./components/common/setting/privacyPolicy";
import TermCondtion from "./components/common/setting/termCondition";
import Privacy from "./components/landingComponents/setting/privacy";
import Terms from "./components/landingComponents/setting/term";
import Signup from "./pages/user/auth/signup";

const CommonRoutes = ({ isAuthenticated }) => (
  <>
    <Route path="/" element={<HomePage />} />
    <Route path="/all-building" element={<AllbuildingPage />} />
    <Route path="/all-building/details/:id?" element={<BuildingDetailPage />} />
    <Route path="/user-login" element={<Login />} />
    <Route path="/user-signup" element={<Signup />} />
    <Route path="/admin-login" element={<Login />} />
    <Route path="/owner-login" element={<Login />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/opt" element={<OPT />} />
    <Route path="/new-password" element={<NewPassword />} />
    <Route path="/privacy-policy" element={<Privacy />} />
    <Route path="/term-condition" element={<Terms />} />
    <Route
      path="/profile"
      element={isAuthenticated ? <Profile /> : <Navigate to="/" />}
    />
    <Route
      path="/profile/edit-profile/:id?"
      element={isAuthenticated ? <EditProfile /> : <Navigate to="/" />}
    />
    <Route
      path="/privacy"
      element={isAuthenticated ? <PrivacyPolicy /> : <Navigate to="/" />}
    />
    <Route
      path="/terms"
      element={isAuthenticated ? <TermCondtion /> : <Navigate to="/" />}
    />
  </>
);

export default CommonRoutes;
