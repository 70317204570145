import React, { useState, useEffect } from "react";
import Banner from "./banner";
import BuildingDetail from "./buildingDetail";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../common/loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BuildingDetailIndex = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloor] = useState([]);
  const [floorDetail, setFloorDetail] = useState([]);
  const [activeFloor, setActiveFloor] = useState(
    floors.length > 0 ? floors[0].id : null
  );

  useEffect(() => {
    if (floors.length > 0) {
      setActiveFloor(floors[0].id);
    }
  }, [floors]);

  // building api
  const fetchBuildings = async () => {
    try {
      setSpinner(true);
      const response = await axios.get(`${BASE_URL}building_details?id=${id}`);
      setBuildings(response.data.data.building);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching buildings:", error);
    }
  };
  useEffect(() => {
    fetchBuildings();
  }, []);
  // floor api
  const fetchFloors = async () => {
    try {
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}get_all_floors?building_id=${id}`
      );
      const data = response.data.data.floors.data;

      setFloor(data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching floors:", error);
    }
  };
  useEffect(() => {
    fetchFloors();
  }, []);
  // floor detail api
  useEffect(() => {
    if (activeFloor) {
      fetchFloorDetail();
    }
  }, [activeFloor]);
  const fetchFloorDetail = async () => {
    try {
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}floor_details?id=${activeFloor}`
      );
      const data = response.data.data.floor;
      setFloorDetail(data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching floors:", error);
    }
  };
  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <Banner buildings={buildings} />
      <BuildingDetail
        floors={floors}
        activeFloor={activeFloor}
        setActiveFloor={setActiveFloor}
        floorDetail={floorDetail}
        setSpinner={setSpinner}
        toast={toast}
      />
    </>
  );
};

export default BuildingDetailIndex;
