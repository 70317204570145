import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";

const WingInsight = () => {
  const timeLabels = [];
  for (let i = 0; i < 24; i++) {
    const timeLabel = moment({ hour: i }).format("h A");
    timeLabels.push(timeLabel);
  }

  const chartData = {
    series: [
      {
        name: "Room utilization",
        data: [
          44, 95, 57, 56, 81, 58, 93, 45, 60, 75, 82, 90, 67, 58, 49, 83, 72,
          88, 79, 64, 55, 48, 70, 85,
        ],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          endingShape: "rounded",
          borderRadius: 5,
        },
      },
      colors: "#0E9FB5",
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: timeLabels,
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        markers: {
          fillColors: ["#4A3AFF", "#C893FD"],
        },
      },
    },
  };

  return (
    <div className="room-usage-insights mt-3">
      <div className="space-analytics-common-wrapper">
        <div className="space-analytics-header">
          <h1>
            <span>Statistics</span>Utilization Every third Minutes for today
          </h1>
        </div>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={250}
        />
      </div>
    </div>
  );
};

export default WingInsight;
