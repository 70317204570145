import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadIcon from "../../../../../assets/images/upload-icon.png";

const AddFile = ({ onShowImageData }) => {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/svg+xml": [".svg"],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      onShowImageData(acceptedFiles);
    },
  });
  const handleDeleteImage = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    onShowImageData(updatedFiles);
  };
  return (
    <div>
      <div {...getRootProps()} className="dropzone custom-dropzone mt-3">
        <input {...getInputProps()} />
        <img src={UploadIcon} alt="image" />
        <p>
          Drag and drop file here <span>OR</span>
        </p>
        <button type="button">Browse File</button>
      </div>
      <div className="uploaded-images">
        {files.map((file, index) => (
          <div key={index} className="uploaded-image">
            <img src={URL.createObjectURL(file)} alt={`Image ${index}`} />
            <button onClick={() => handleDeleteImage(index)}>X</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddFile;
