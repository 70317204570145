import React, { useState, useRef, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const UtilizationBarChart = () => {
  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        data: [50, 30, 48, 70, 40, 80, 90, 20, 60, 80],
      },
    ],

    options: {
      colors: "#24CCA7",
      chart: {
        type: "bar",
        height: 300,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadiusApplication: "end",
          horizontal: true,
          columnWidth: "15%",
          barHeight: "50%",
        },
      },
      grid: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 10,
        title: {
          text: "No Of Attandance",
        },
      },
    },
  });

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleStartDateChange = (date) => {
    const startOfWeek = moment(date).toDate();
    const endOfWeek = moment(date).add(6, "days").toDate();
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };
  const toggleDatePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };
  const datePickerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="utilization-bar-chart mt-3">
      <div className="utilization-bar-chart-header">
        <div className="graph-heading pb-0">
          <h1 className="m-0">
            <span>Statistics</span>
            Meeting By Size
          </h1>
        </div>
        <p>
          Present {moment(startDate).format("MMMM D")} -{" "}
          {moment(endDate).format("MMMM D")}
        </p>
        <div className="range-picker-wrapper">
          <button className="date-toggle" onClick={toggleDatePicker}>
            Date Range
            <KeyboardArrowDownIcon />
          </button>
          {isPickerOpen && (
            <div className="range-picker" ref={datePickerRef}>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsStart
                inline
              />
            </div>
          )}
        </div>
      </div>
      <div className="utilization-bar-chart-body">
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default UtilizationBarChart;
