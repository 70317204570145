import React, { useEffect } from "react";
import BuildingIcon from "../../../assets/images/building-icon.png";
const UserValueCard = ({ spaces, requests }) => {
  const spaceLenght = spaces?.length;
  const requestLenght = requests?.length;
  const totalLength = spaceLenght + requestLenght;

  return (
    <div className="user-stat-left">
      <div className="value-card">
        <div className="value-card-header">
          <h1>
            <img src={BuildingIcon} alt="img" />
            Spaces
          </h1>
        </div>
        <div className="value-card-body">
          <h2>
            No of Spaces<span>Total</span>
          </h2>
          <h1>{spaceLenght}</h1>
        </div>
      </div>
      <div className="value-card">
        <div className="value-card-header">
          <h1>
            <img src={BuildingIcon} alt="img" />
            Requests
          </h1>
        </div>
        <div className="value-card-body">
          <h2>
            No of Requests<span>Total</span>
          </h2>
          <h1>{totalLength}</h1>
        </div>
      </div>
    </div>
  );
};

export default UserValueCard;
