import React from "react";
import Logo from "../../../assets/images/landingImages/logo.png";
import { NavLink } from "react-router-dom";
const LandingFooter = () => {
  return (
    <div className="container-fluid landing-footer-section">
      <div className="container landing-footer-container">
        <img className="footer-logo" src={Logo} alt="img" />
        <ul>
          <li>
            <NavLink>Home</NavLink>
          </li>
          <li>
            <NavLink to="/all-building">Product</NavLink>
          </li>
          <li>
            <NavLink>Contact</NavLink>
          </li>
        </ul>
      </div>
      <div className="footer-copyright">
        <p>Copyright © 2024 Space Management System. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default LandingFooter;
