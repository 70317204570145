import React, { useEffect } from "react";
import SearchIcon from "../../../assets/images/search-icon.png";
import Loader from "../../common/loader/loader";
import Pagination from "../../common/pagination/pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserRequests,
  setPageNumber,
  setSearchQuery,
} from "../../../slices/requestSlice";
const MyRequest = () => {
  const { requests, loading, totalPageCount, searchQuery, pageNumber } =
    useSelector((state) => state.requests);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserRequests({ pageNumber, searchQuery }));
  }, [dispatch, searchQuery, pageNumber]);

  const handleSearchChange = (e) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const handlePageChange = (newPageNumber) => {
    dispatch(setPageNumber(newPageNumber));
  };

  return (
    <>
      {loading && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="common-bg">
        <div className="container-fluid">
          <div className="common-section">
            <div className="custom-table-header">
              <h1>List of User Request</h1>
            </div>
            <div className="filter-wrapper mt-4 mb-3">
              <div className="filter-wrapper-left">
                <div className="search-field">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <img src={SearchIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="table-body">
              <div className="custom-table">
                <div className="table-header-row">
                  <div className="table-cell">User</div>
                  <div className="table-cell">Building</div>
                  <div className="table-cell">Request Date</div>
                  <div className="table-cell">Request Time</div>
                  <div className="table-cell">Floor</div>
                  <div className="table-cell">Space</div>
                  <div className="table-cell">Status</div>
                </div>
                {requests && requests.length > 0 ? (
                  requests.map((request) => (
                    <div className="table-row">
                      <div className="table-cell" data-heading="User">
                        <div className="table-cell-inner">
                          {request?.user?.username || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Building">
                        <div className="table-cell-inner">
                          {request?.building?.building_name || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Request Date">
                        <div className="table-cell-inner">
                          {request?.created_date || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Request Time">
                        <div className="table-cell-inner">
                          {request?.created_at || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Floor">
                        <div className="table-cell-inner">
                          {request?.floor?.floor_name || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Space">
                        <div className="table-cell-inner">
                          {request?.space?.space_name || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Status">
                        <div
                          className={`table-cell-inner status ${request.status}`}
                        >
                          {request?.status || "-"}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data-message">No data available</div>
                )}
              </div>
            </div>
            {totalPageCount > 1 && (
              <Pagination
                totalPageCount={totalPageCount}
                currentPage={pageNumber}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyRequest;
