import { useState } from "react";
import Filters from "../roomUtilization/filters";
import { useBuildingData } from "../common/useBuildingData";
import Loader from "../../common/loader/loader";
import ReactApexChart from "react-apexcharts";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WingIcon from "../../../assets/images/wing-icon.png";
import WingInsight from "./wingInsight";
import WingUtilization from "./wingUtilization";

const WingDetail = () => {
  const {
    loading,
    error,
    buildingNames,
    floorNames,
    roomNames,
    roomUtilizationData,
    selectedRoomName,
    handleBuildingChange,
    handleFloorChange,
    handleRoomChange,
  } = useBuildingData();
  const [series] = useState([75, 25]);
  const [options] = useState({
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    colors: ["#24CCA7", "#F1F1F1"],
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "16px",
              fontWeight: "bold",
              color: "#000",
              formatter: (value) => "Total",
            },
            value: {
              show: true,
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000",
              formatter: (value) => value,
            },
          },
        },
      },
    },
    labels: ["Unutilization", "Utilization"],
  });
  return (
    <>
      {loading && <Loader />}{" "}
      <div className="common-bg">
        <div className="container-fluid">
          {error && (
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="wing-detail-section">
            <Filters
              handleBuildingChange={handleBuildingChange}
              buildingNames={buildingNames}
              handleFloorChange={handleFloorChange}
              floorNames={floorNames}
              handleRoomChange={handleRoomChange}
              roomNames={roomNames}
            />
            <div className="wing-insight-wrapper mt-3">
              <div className="donut-chart-box">
                <div className="graph-heading pb-0">
                  <h1 className="m-0">
                    <span>Statistics</span>
                    Wing 1 Current Utilization
                  </h1>
                </div>
                <div className="donut-chart mt-2">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="donut"
                    width={300}
                  />
                </div>
                <p>
                  <AccessTimeIcon className="clock-icon" />
                  Updated on 03-August-2023 06:00 AM
                </p>
              </div>
              <div className="wing-insight-col">
                <div className="wing-insight-row">
                  <div className="wing-insight-card">
                    <div className="wing-insight-card-header">
                      <h1>
                        <img src={WingIcon} alt="" />
                        <span>Zone Capacity</span>
                      </h1>
                    </div>
                    <div className="wing-insight-card-body">
                      <h1>16</h1>
                    </div>
                  </div>
                  <div className="wing-insight-card">
                    <div className="wing-insight-card-header">
                      <h1>
                        <img src={WingIcon} alt="" />
                        <span>Zone Capacity</span>
                      </h1>
                    </div>
                    <div className="wing-insight-card-body">
                      <h1>16</h1>
                    </div>
                  </div>
                  <div className="wing-insight-card">
                    <div className="wing-insight-card-header">
                      <h1>
                        <img src={WingIcon} alt="" />
                        <span>Zone Capacity</span>
                      </h1>
                    </div>
                    <div className="wing-insight-card-body">
                      <h1>16</h1>
                    </div>
                  </div>
                </div>
                <div className="wing-insight-row">
                  <div className="wing-insight-card">
                    <div className="wing-insight-card-header">
                      <h1>
                        <img src={WingIcon} alt="" />
                        <span>Zone Capacity</span>
                      </h1>
                      <p>06:00 AM - 06:30 AM</p>
                    </div>
                    <div className="wing-insight-card-body">
                      <h1>16</h1>
                    </div>
                  </div>
                  <div className="wing-insight-card">
                    <div className="wing-insight-card-header">
                      <h1>
                        <img src={WingIcon} alt="" />
                        <span>Zone Capacity</span>
                      </h1>
                      <p>06:00 AM - 06:30 AM</p>
                    </div>
                    <div className="wing-insight-card-body">
                      <h1>16</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <WingInsight />
            <WingUtilization />
          </div>
        </div>
      </div>
    </>
  );
};

export default WingDetail;
