import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialState = {
  loading: false,
  spaces: [],
  error: "",
  totalPageCount: 0,
  searchQuery: "",
  pageNumber: 1,
};
export const fetchSpaces = createAsyncThunk(
  "space/fetchSpaces",
  async ({ searchQuery, pageNumber }) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${BASE_URL}my_spaces`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        search: searchQuery,
      },
    });
    return {
      spaces: response?.data?.data?.spaces?.data,
      lastPage: response?.data?.data?.spaces?.last_page,
    };
  }
);
const spacesSlice = createSlice({
  name: "space",
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
      state.pageNumber = 1;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpaces.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSpaces.fulfilled, (state, action) => {
      state.loading = false;
      state.spaces = action.payload.spaces;
      state.totalPageCount = action.payload.lastPage;
    });
    builder.addCase(fetchSpaces.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export const { setSearchQuery, setPageNumber } = spacesSlice.actions;
export default spacesSlice.reducer;
