import React, { useState, useEffect } from "react";
import AddRequest from "./addRequest";
import { useNavigate } from "react-router-dom";
const BuildingDetail = ({
  floors,
  activeFloor,
  setActiveFloor,
  floorDetail,
  setSpinner,
  toast,
}) => {
  let navigation = useNavigate();
  const [pathId, setPathId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [spaceName, setSpaceName] = useState("");
  const [isUser, setIsUser] = useState(false);

  const handleButtonClick = (floorId) => {
    setActiveFloor(floorId);
  };
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("token");
    if (isAuthenticated) {
      const userAuth = localStorage.getItem("user");
      const authType = JSON.parse(userAuth);
      setIsUser(authType.type === "user");
    }
  }, []);
  useEffect(() => {
    applyInitialSpaceColor();
  }, [floorDetail]);
  const applyInitialSpaceColor = () => {
    applySpaceColor(floorDetail);
  };
  const applySpaceColor = (floorData) => {
    if (floorData && floorData.spaces) {
      floorData.spaces.forEach((space) => {
        let hasSpaceColor = false;
        let hasWifi = false;
        let hasRouter = false;

        const pathId = `room${space.path_id}`;
        const pathElement = document.getElementById(pathId);

        if (pathElement && space.space_color) {
          pathElement.style.fill = space.space_color;
          hasSpaceColor = true;
        }

        const enableButtonId = `enable-btn${space.path_id}`;
        const enableButton = document.getElementById(enableButtonId);
        const enableButtonIcons = document.getElementsByClassName("svgBtnicon");
        if (enableButton) {
          enableButton.style.display = hasSpaceColor ? "block" : "none";
        }
        for (let i = 0; i < enableButtonIcons.length; i++) {
          enableButtonIcons[i].style.display = hasSpaceColor ? "block" : "none";
        }
        const textElement = document.getElementById(
          `space-name${space.path_id}`
        );
        if (textElement) {
          textElement.textContent = space.space_name;
          textElement.style.display = space.space_color ? "block" : "none";
        }
        if (space.devices) {
          if (space.devices.includes("Wifi")) {
            hasWifi = true;
          }
          if (space.devices.includes("Router")) {
            hasRouter = true;
          }
        }
        const wifiId = `wifi${space.path_id}`;
        const wifiElement = document.getElementById(wifiId);

        if (wifiElement) {
          wifiElement.style.display = hasWifi ? "block" : "none";
        }

        const routerId = `router${space.path_id}`;
        const routerElement = document.getElementById(routerId);

        if (routerElement) {
          routerElement.style.display = hasRouter ? "block" : "none";
        }
      });
    }
  };
  const handleSvg = (event) => {
    if (isUser) {
      const targetId = event.target.id;
      if (targetId.startsWith("enable-btn")) {
        const circleNumber = targetId.replace("enable-btn", "");
        setPathId(circleNumber);
        setModalShow(true);
        const pathElement = document.getElementById(`path${circleNumber}`);
        if (pathElement) {
          pathElement.classList.add("highlighted");
        }
        const space = floorDetail.spaces.find(
          (space) => space.path_id == circleNumber
        );
        if (space) {
          setSpaceName(space.space_name);
        } else {
          setSpaceName("-");
        }
      }
    } else {
      toast.error("You are not logged in. Please log in first.", {
        onClose: () => {
          navigation("/user-login");
        },
      });
    }
  };

  return (
    <>
      <div className="floor-svg-section">
        <div className="__floor-tab-wrapper">
          {floors.map((floor) => (
            <button
              key={floor.id}
              onClick={() => handleButtonClick(floor.id)}
              className={activeFloor === floor.id ? "active" : ""}
            >
              {floor.floor_name}
            </button>
          ))}
        </div>
        <div className="__svg-wrapper">
          <div
            onClick={handleSvg}
            dangerouslySetInnerHTML={{ __html: floorDetail?.svg_code || "-" }}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <AddRequest
        show={modalShow}
        onHide={() => setModalShow(false)}
        setSpinner={setSpinner}
        toast={toast}
        spaceName={spaceName}
        pathId={pathId}
        activeFloor={activeFloor}
        floorDetail={floorDetail}
      ></AddRequest>
    </>
  );
};

export default BuildingDetail;
