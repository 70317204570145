import { Routes, useLocation, Navigate, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/design/style.scss";
import Navbar from "./components/common/navbar/navbar";
import LandingNavbar from "./components/common/landingLayout/landingNavbar";
import LandingFooter from "./components/common/landingLayout/landingFooter";
import AdminRoutes from "./AdminRoutes";
import UserRoutes from "./UserRoutes";
import CommonRoutes from "./CommonRoutes";

function App() {
  const location = useLocation();
  const navbarHiddenPaths = [
    "/user-login",
    "/forgot-password",
    "/opt",
    "/new-password",
    "/admin-login",
    "/owner-login",
    "/user-signup",
  ];
  const isLandingPagePaths = [
    "/",
    "/all-building",
    "/all-building/details/.*",
    "/privacy-policy",
    "/term-condition",
  ];
  const isLandingPage = isLandingPagePaths.some((path) =>
    new RegExp(`^${path}(/.*)?$`).test(location.pathname)
  );
  const isNavbarVisible = !navbarHiddenPaths.includes(location.pathname);
  const isAuthenticated = localStorage.getItem("token");
  let isAdmin = false;
  let isUser = false;
  if (isAuthenticated) {
    const userAuth = localStorage.getItem("user");
    const authType = JSON.parse(userAuth);
    isAdmin = authType.type === "admin";
    isUser = authType.type === "user";
  }
  return (
    <>
      {isNavbarVisible && !isLandingPage ? <Navbar /> : null}
      {isLandingPage ? <LandingNavbar /> : null}
      <Routes>
        {CommonRoutes({ isAuthenticated })}
        {AdminRoutes({ isAuthenticated, isAdmin })}
        {UserRoutes({ isAuthenticated, isUser })}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {isLandingPage && <LandingFooter />}
    </>
  );
}

export default App;
