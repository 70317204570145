import React from "react";
import { NavLink } from "react-router-dom";
const FeaturesBuildings = ({ buildings }) => {
  return (
    <div className="container-fluid feature-building-section">
      <div className="container feature-building-container">
        <div className="feature-building-header">
          <h1>
            Features Buildings
            <span>Lorem Ipsum is simply dummy text simply dummy text</span>
          </h1>
        </div>
        <div className="feature-building-body mt-5">
          <div className="row ">
            {buildings.slice(0, 3).map((building, index) => (
              <div className="col-md-6 col-lg-4 mb-3" key={index}>
                <div className="feature-building-card">
                  {building.files && building.files.length > 0 && (
                    <img src={building.files[0]} alt="" />
                  )}
                  <div className="__card-overlay">
                    <div className="__overlay-content">
                      <h1>
                        {building.building_name}
                        <span>{building.location}</span>
                      </h1>
                      <NavLink to={`/all-building/details/${building.id}`}>
                        View Details
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.19531 8.27344H15.1953M15.1953 8.27344L8.19531 1.27344M15.1953 8.27344L8.19531 15.2734"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="feature-building-footer mt-3">
          <NavLink to="/all-building">See All Buildings</NavLink>
        </div>
      </div>
    </div>
  );
};

export default FeaturesBuildings;
