import React from "react";
import { NavLink } from "react-router-dom";
const UserAllBuilding = ({ buildings }) => {
  return (
    <div className="common-section mt-3">
      <div className="custom-table-header">
        <h1>List of all Buildings</h1>
        <NavLink to="/all-building">See All</NavLink>
      </div>
      <div className="table-body">
        <div className="custom-table">
          <div className="table-header-row">
            <div className="table-cell">Name</div>
            <div className="table-cell">Location</div>
            <div className="table-cell">Building Owner</div>
          </div>
          {buildings.map((building) => (
            <div className="table-row">
              <div className="table-cell" data-heading="Name:">
                <div className="table-cell-inner">
                  <img
                    className="building-img"
                    src={building.files[0]}
                    alt="img"
                  />
                  {building.building_name ?? "-"}
                </div>
              </div>
              <div className="table-cell" data-heading="Location:">
                <div className="table-cell-inner">
                  {building.location ?? "-"}
                </div>
              </div>
              <div className="table-cell" data-heading="Building Owner:">
                <div className="table-cell-inner">
                  <img
                    className="profile-img"
                    src={building.owner.image}
                    alt="img"
                  />
                  <span>
                    {building.owner.username ?? "-"}
                    <label>{building.owner.email ?? "-"}</label>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserAllBuilding;
