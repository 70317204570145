import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalCloseBtn from "../../../../assets/images/modal-close-btn.png";
import UserIconGreen from "../../../../assets/images/user-icon-primary.png";
function AddUser({ show, onHide, setSpinner, toast, handleUserAdd }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    phone_number: "",
    password: "",
  });
  const addUser = async () => {
    if (userData.password !== userData.confirmPassword) {
      setError("Password and confirm password must match");
      return;
    }
    if (userData.password.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }
    setSpinner(true);
    try {
      const token = localStorage.token;
      const response = await axios.post(`${BASE_URL}user/add_user`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Add user successfully!");
      setSpinner(false);
      handleUserAdd(response.data.data.user);
      setUserData({
        username: "",
        email: "",
        phone_number: "",
        password: "",
        confirmPassword: "",
      });
      setError(null);
      onHide();
    } catch (error) {
      const errorContent = error.response.data.message;
      setError(errorContent);
      setSpinner(false);
    }
  };
  const handleModalHide = () => {
    setUserData({
      username: "",
      email: "",
      phone_number: "",
      password: "",
    });
    onHide();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal-header pb-0">
        <Button className="modal-close-button" onClick={handleModalHide}>
          <img src={ModalCloseBtn} alt="image" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="moda-body-content">
          <div className="modal-content-header">
            <img src={UserIconGreen} alt="image" />
            <h1>Add New Users</h1>
            <p>
              Please fill the Information to <strong>Add</strong> New{" "}
              <strong>User</strong>.
            </p>
          </div>
          <form>
            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="mb-3 modal-form-field">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name Here"
                name="username"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 modal-form-field">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email Here"
                name="email"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 modal-form-field">
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Phone Here"
                name="phone_number"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 modal-form-field">
              <label>Pasword</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password Here"
                name="password"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 modal-form-field">
              <label>Confirm Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Your Password"
                name="confirmPassword"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 modal-form-field-btn">
              <button type="button" onClick={onHide}>
                Cancel
              </button>
              <button type="button" onClick={addUser}>
                Add New User
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddUser;
