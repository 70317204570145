import React from "react";

const Filters = ({
  handleBuildingChange,
  buildingNames,
  handleFloorChange,
  floorNames,
  handleRoomChange,
  roomNames,
}) => {
  return (
    <div className="filter-common-wrapper mt-3">
      <div className="filter-heading">
        <h1>Filters</h1>
      </div>
      <div className="row">
        <div className="my-3 modal-form-field col-md-4">
          <label>Select Building</label>
          <select className="form-select" onChange={handleBuildingChange}>
            {buildingNames.map((building) => (
              <option key={building.id} value={building.id}>
                {building.building_name}
              </option>
            ))}
          </select>
        </div>
        <div className="my-3 modal-form-field col-md-4">
          <label>Select Floor</label>
          <select className="form-select" onChange={handleFloorChange}>
            {floorNames.map((floor) => (
              <option key={floor.id} value={floor.id}>
                {floor.floor_name}
              </option>
            ))}
          </select>
        </div>
        <div className="my-3 modal-form-field col-md-4">
          <label>Select Room</label>
          <select className="form-select" onChange={handleRoomChange}>
            {roomNames.map((room) => (
              <option key={room.id} value={room.id}>
                {room.space_name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Filters;
