import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import UserIconGreen from "../../../assets/images/building-modal-icon.png";
import ModalCloseBtn from "../../../assets/images/modal-close-btn.png";
import axios from "axios";

const AddRequest = ({
  show,
  onHide,
  setSpinner,
  toast,
  spaceName,
  pathId,
  activeFloor,
  floorDetail,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    checkin_date: "",
    checkin_time: "",
    checkout_date: "",
    checkout_time: "",
    description: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAddRequest = async () => {
    try {
      setSpinner(true);
      const token = localStorage.token;
      await axios.post(
        `${BASE_URL}user/user_request`,
        {
          ...formData,
          building_id: id,
          path_id: pathId,
          floor_id: activeFloor,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onHide();
      toast.success("Request submit Successfully");
      setSpinner(false);
      setError(null);
      setFormData({
        checkin_date: "",
        checkin_time: "",
        checkout_date: "",
        checkout_time: "",
        description: "",
      });
    } catch (error) {
      setError(error.response.data.message);
      setSpinner(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-bottom-right"
    >
      <Modal.Header className="modal-header pb-0">
        <Button className="modal-close-button" onClick={onHide}>
          <img src={ModalCloseBtn} alt="Close" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="moda-body-content">
          <div className="modal-content-header">
            <img src={UserIconGreen} alt="User Icon" />
            <h1>Enter Information</h1>
          </div>
          <form>
            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="row mt-3">
              <div className="col-lg-6">
                <div className="mb-3 modal-form-field">
                  <label>Check In Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="checkin_date"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3 modal-form-field">
                  <label>Check In Time</label>
                  <input
                    type="time"
                    className="form-control"
                    name="checkin_time"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3 modal-form-field">
                  <label>Check Out Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="checkout_date"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3 modal-form-field">
                  <label>Check Out Time</label>
                  <input
                    type="time"
                    className="form-control"
                    name="checkout_time"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3 modal-form-field">
                  <label>Floor Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={floorDetail?.floor_name || "-"}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3 modal-form-field">
                  <label>Space Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={spaceName || "-"}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3 modal-form-field">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    placeholder="Description Here"
                    name="description"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="mb-3 modal-form-field-btn">
              <button type="button" onClick={onHide}>
                Cancel
              </button>
              <button type="button" onClick={handleAddRequest}>
                Send Request
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddRequest;
