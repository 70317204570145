import React, { useEffect } from "react";
import UserAllBuilding from "./userAllBuilding";
import Loader from "../../common/loader/loader";
import { useSelector, useDispatch } from "react-redux";
import { fetchBuildings } from "../../../slices/buildingsSlice";
import { fetchSpaces } from "../../../slices/spacesSlice";
import { fetchUserRequests } from "../../../slices/requestSlice";
import { fetchSpaceGraphData } from "../../../slices/spaceGraphSlice";
import UserValueCard from "./userValueCard";
import UserDemographic from "./userDemographic";
const UserDashboard = () => {
  const dispatch = useDispatch();
  const { loading: buildingsLoading, buildings } = useSelector(
    (state) => state.buildings
  );
  const { loading: spacesLoading, spaces } = useSelector(
    (state) => state.spaces
  );
  const { loading: requestsLoading, requests } = useSelector(
    (state) => state.requests
  );
  const { loading: spaceGraphLoading, spaceGraphData } = useSelector(
    (state) => state.spaceGraph
  );

  useEffect(() => {
    dispatch(fetchBuildings({}));
    dispatch(fetchSpaces({}));
    dispatch(fetchUserRequests({}));
    dispatch(fetchSpaceGraphData());
  }, [dispatch]);
  const isLoading =
    buildingsLoading || spacesLoading || requestsLoading || spaceGraphLoading;
  return (
    <>
      {isLoading && <Loader />}
      <div className="common-bg">
        <div className="container-fluid">
          <div className="user-stat-section">
            <UserValueCard spaces={spaces} requests={requests} />
            <UserDemographic spaceGraphData={spaceGraphData} />
          </div>
          <UserAllBuilding buildings={buildings} />
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
