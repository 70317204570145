import React from "react";
import LocationIcon from "../../../../assets/images/location.png";
import { NavLink } from "react-router-dom";
const BuildingDetail = ({ building }) => {
 
  return (
    <div className="common-section mt-3 Building-information-section">
      <div className="__information-header">
        <div className="__buildingname">
          <h1>
            {building.building_name} <span>{building.total_floors} Floors</span>
          </h1>
        </div>
        <div className="__btn-wrapper">
          <NavLink
            className="__floor-map"
            to={`/building/floor/detail/${building.id}`}
          >
            See Floors Map
          </NavLink>
          <NavLink
            className="__floor-list"
            to={`/building/floor/${building.id}`}
          >
            See Floor Lists
          </NavLink>
        </div>
      </div>
      <div className="__information-body mt-3">
        <p>{building.descriptions}</p>
      </div>
      <div className="__information-footer mt-3">
        <p>
          <img src={LocationIcon} alt="icon" /> {building.location}
        </p>
      </div>
    </div>
  );
};

export default BuildingDetail;
