import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialState = {
  loading: false,
  buildings: [],
  error: "",
  totalPageCount: 0,
  searchQuery: "",
  pageNumber: 1,
};
export const fetchBuildings = createAsyncThunk(
  "buildings/fetchBuildings",
  async ({ pageNumber, searchQuery }) => {
    const response = await axios.get(`${BASE_URL}get_all_buildings`, {
      params: {
        page: pageNumber,
        search: searchQuery,
      },
    });
    return {
      buildings: response.data.data.buildings.data,
      lastPage: response.data.data.buildings.last_page,
    };
  }
);
const buildingsSlice = createSlice({
  name: "buildings",
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
      state.pageNumber = 1;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBuildings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBuildings.fulfilled, (state, action) => {
      state.loading = false;
      state.buildings = action.payload.buildings;
      state.totalPageCount = action.payload.lastPage;
    });
    builder.addCase(fetchBuildings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export const { setSearchQuery, setPageNumber } = buildingsSlice.actions;
export default buildingsSlice.reducer;
