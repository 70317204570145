import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const FloorBarChart = ({
  floorGraph = { categories: [], free: [], occupied: [] },
}) => {
  console.log(floorGraph, "floor");
  const { categories, free, occupied } = floorGraph;
  const [series, setSeries] = useState([
    {
      name: "Occupied",
      data: [],
    },
    {
      name: "Free",
      data: [],
    },
  ]);

  const [options, setOptions] = useState({
    colors: ["#24CCA7", "#E5E5EF"],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "25%",
        borderRadius: 10,
      },
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
      position: "right",
      offsetX: 0,
      offsetY: 50,
    },
  });
  useEffect(() => {
    setSeries([
      {
        name: "Occupied",
        data: occupied,
      },
      {
        name: "Free",
        data: free,
      },
    ]);

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: categories,
      },
    }));
  }, [floorGraph]);
  return (
    <div className="chart-wrapper mt-3 ">
      <div className="chart-heading">
        <h1>
          <span>Statistics</span> Floor
        </h1>
        <div className="chart-status">
          <h1>
            <div className="status-box very-top floor"></div>Occupied
          </h1>
          <h1>
            <div className="status-box top floor"></div>Free
          </h1>
        </div>
      </div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default FloorBarChart;
