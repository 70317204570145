import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../common/loader/loader";
const Terms = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(null);
  const [editorData, setEditorData] = useState("");
  useEffect(() => {
    fetchTermData();
  }, []);

  const fetchTermData = async () => {
    try {
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}get-terms-conditions?type=1`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data.terms.description;
      setEditorData(data);
      setSpinner(false);
    } catch (error) {
      setError(error?.response?.data?.message);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <div className="container-fluid home-section landing-building-section">
        <div className="container home-container">
          <h1>Terms and Condition </h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
      </div>
      <div className="setting-wrapper">
        <div className="container">
          <div className="common-section">
            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <div className="mt-3">
              <div className="py-2 editor-content ">
                <div dangerouslySetInnerHTML={{ __html: editorData || "--" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
