import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialState = {
  requests: [],
  totalPageCount: 0,
  pageNumber: 1,
  searchQuery: "",
  loading: false,
  error: null,
};
export const fetchUserRequests = createAsyncThunk(
  "myRequest/fetchUserRequests",
  async ({ pageNumber, searchQuery }) => {
    const token = localStorage.token;
    const response = await axios.get(`${BASE_URL}get_all_requests`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        search: searchQuery,
      },
    });
    const adjustedData = response?.data?.data?.userRequests?.data.map(
      (request) => ({
        ...request,
        created_at: moment
          .utc(request.created_at)
          .add(5, "hours")
          .format("hh:mm A"),
        created_date: moment
          .utc(request.created_at)
          .add(5, "hours")
          .format("YYYY-MM-DD"),
      })
    );
    return {
      requests: adjustedData,
      lastPage: response?.data?.data?.userRequests?.last_page,
    };
  }
);

const myRequestSlice = createSlice({
  name: "myRequest",
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
      state.pageNumber = 1;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRequests.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserRequests.fulfilled, (state, action) => {
        state.requests = action.payload.requests;
        state.totalPageCount = action.payload.lastPage;
        state.loading = false;
      })
      .addCase(fetchUserRequests.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});
export const { setSearchQuery, setPageNumber } = myRequestSlice.actions;
export default myRequestSlice.reducer;
