import React from "react";
import { Tooltip } from "react-tooltip";

const weeklyData = [
  {
    wing: "Wing 1",
    daily: {
      Mon: 1800,
      Tue: 1500,
      Wed: 2000,
      Thu: 1700,
      Fri: 1600,
      Sat: 2200,
      Sun: 1700,
    },
  },
  {
    wing: "Wing 2",
    daily: {
      Mon: 1400,
      Tue: 1300,
      Wed: 1900,
      Thu: 1600,
      Fri: 1500,
      Sat: 1100,
      Sun: 1300,
    },
  },
  {
    wing: "Wing 3",
    daily: {
      Mon: 1000,
      Tue: 600,
      Wed: 1000,
      Thu: 1800,
      Fri: 1200,
      Sat: 1500,
      Sun: 100,
    },
  },
  {
    wing: "Wing 4",
    daily: {
      Mon: 1400,
      Tue: 1300,
      Wed: 1900,
      Thu: 1600,
      Fri: 1500,
      Sat: 1100,
      Sun: 1300,
    },
  },
];

const timeData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const findRanks = (dailyData) => {
  const values = Object.values(dailyData);
  const max = Math.max(...values);
  const min = Math.min(...values);

  let secondMax = -Infinity;
  let thirdMax = -Infinity;

  values.forEach((value) => {
    if (value > secondMax && value < max) {
      thirdMax = secondMax;
      secondMax = value;
    } else if (value > thirdMax && value < secondMax) {
      thirdMax = value;
    }
  });

  return { max, secondMax, thirdMax, min };
};

const getTrafficClass = (value, ranks) => {
  const { max, secondMax, thirdMax, min } = ranks;

  if (value === max) return "FullyOccupied";
  if (value === secondMax) return "MidLevelOccupied";
  if (value === thirdMax) return "MidFree";
  if (value === min) return "FullFree";
  return "FullFree";
};

const WingGridChart = ({ view }) => {
  return (
    <div className="space-analytics-grid-chart">
      <div className="heat-map-body">
        <div className="week-days">
          <ul>
            {weeklyData.map((wingData, index) => (
              <li key={index}>{wingData.wing}</li>
            ))}
          </ul>
        </div>
        <div className="hourly-grid">
          {timeData.map((day) => (
            <div className="grid-col" key={day}>
              {weeklyData.map((wingData, index) => {
                const ranks = findRanks(wingData.daily);
                const value = wingData.daily[day];

                return (
                  <div
                    className={`grid-box ${getTrafficClass(value, ranks)}`}
                    key={index}
                    data-tooltip-id={`tooltip-${index}-${day}`}
                  >
                    {/* {value} */}
                  </div>
                );
              })}
              <div className="grid-box time">{day}</div>
            </div>
          ))}
        </div>
      </div>

      {weeklyData.map((wingData, wingIndex) =>
        timeData.map((day, dayIndex) => (
          <Tooltip
            key={`${wingIndex}-${dayIndex}`}
            id={`tooltip-${wingIndex}-${day}`}
          >
            <div className="tooltip-data">
              <span>{wingData.wing}</span>
              <span>{day}</span>
              <span>{wingData.daily[day]}</span>
            </div>
          </Tooltip>
        ))
      )}
    </div>
  );
};

export default WingGridChart;
