import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

console.log(BASE_URL, "url");
export const axiosPostCall = async (endpoint, requestData) => {
  try {
    const response = await axios.post(BASE_URL + endpoint, requestData);
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error;
  }
};
