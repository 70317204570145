import React, { useState, useEffect } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/loader/loader";
const PrivacyPolicy = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(null);
  const [editorData, setEditorData] = useState("");
  useEffect(() => {
    fetchTermData();
  }, []);

  const fetchTermData = async () => {
    try {
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}get-terms-conditions?type=2`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data.data.terms.description;
      setEditorData(data);
      setSpinner(false);
    } catch (error) {
      setError(error?.response?.data?.message);
      setSpinner(false);
    }
  };
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  const handleSendToAPI = async () => {
    const { token } = localStorage;
    setSpinner(true);
    const requestBody = {
      type: 2,
      description: editorData,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}terms-conditions`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setError(null);
      setSpinner(false);
      toast.success(response.data.message);
    } catch (error) {
      setSpinner(false);
      setError(error?.response?.data?.message);
    }
  };
  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="common-bg">
        <div className="container-fluid">
          <div className="common-section">
            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="custom-table-header">
              <h1>Privacy Policy</h1>
            </div>
            <div className="mt-3">
              <CKEditor
                editor={ClassicEditor}
                onChange={handleEditorChange}
                data={editorData}
              />
            </div>
            <div className="__ckBtn mt-3">
              <button onClick={handleSendToAPI}> Save Content</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
