import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = {
  loading: false,
  roomNames: [],
  error: null,
  selectedRoom: null,
  selectedRoomName: null,
};

export const fetchRoomNames = createAsyncThunk(
  "roomName/fetchRoomNames",
  async (floorId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}admin/rooms_names`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          floor_id: floorId,
        },
      });
      return response?.data?.data?.floorsSpaces;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

const roomNameSlice = createSlice({
  name: "roomName",
  initialState,
  reducers: {
    setSelectedRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    setSelectedRoomName: (state, action) => {
      state.selectedRoomName = action.payload;
    },
    clearSelectedRoom: (state) => {
      state.selectedRoom = null;
    },
    clearRooms: (state) => {
      state.roomNames = [];
    },
    resetRoomState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomNames.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoomNames.fulfilled, (state, action) => {
        state.loading = false;
        state.roomNames = action.payload;
      })
      .addCase(fetchRoomNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setSelectedRoom,
  clearSelectedRoom,
  clearRooms,
  resetRoomState,
  setSelectedRoomName,
} = roomNameSlice.actions;
export default roomNameSlice.reducer;
