import React from "react";
import { NavLink } from "react-router-dom";

const Consultation = () => {
  return (
    <div className="container-fluid consultation-section">
      <div className="container consultation-container">
        <h1>Ready to Start Building? Schedule Your Consultation Now!</h1>
        <p>
          Nunc et justo eu ante commodo pretium sed at enim. Aenean at egestas
          met. Fusce sit amet nunc semper, laoreet felis nec.{" "}
        </p>
        <NavLink>Contact Us</NavLink>
      </div>
    </div>
  );
};

export default Consultation;
