import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "../../../../assets/images/search-icon.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import BuildingIconGreen from "../../../../assets/images/building-modal-icon.png";
import ModalCloseBtn from "../../../../assets/images/modal-close-btn.png";
import DotIcon from "../../../../assets/images/dots-horizontal-icon.png";
import EditIcon from "../../../../assets/images/edit-icon.png";
import Loader from "../../../common/loader/loader";
import axios from "axios";
import Pagination from "../../../common/pagination/pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { NavLink } from "react-router-dom";
const BookingPanel = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  const [userRequestData, setUserRequestData] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);
  useEffect(() => {
    fetchUsers(searchQuery);
  }, [pageNumber, searchQuery]);
  const fetchUsers = async (query = "") => {
    setSpinner(true);
    try {
      const token = localStorage.token;
      const response = await axios.get(`${BASE_URL}admin/get_all_requests`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber,
          search: query,
        },
      });
      const adjustedData = response.data.data.userRequests.data.map(
        (request) => ({
          ...request,
          created_at: moment
            .utc(request.created_at)
            .add(5, "hours")
            .format("hh:mm A"),
          created_date: moment
            .utc(request.created_at)
            .add(5, "hours")
            .format("YYYY-MM-DD"),
        })
      );

      setUserRequestData(adjustedData);
      setTotalPages(response.data.data.userRequests.last_page);
      setSpinner(false);
    } catch (error) {
      setError("Error fetching users:", error);
      setSpinner(false);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPageNumber(1);
  };
  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  const viewRequestDetail = async (requestId) => {
    setSpinner(true);
    try {
      const token = localStorage.token;
      const response = await axios.get(
        `${BASE_URL}admin/request_details?request_id=${requestId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data?.data?.requestData;
      setSelectedRequest(data);
      setModalShow(true);
      setSpinner(false);
    } catch (error) {
      setError("Error fetching request details:", error);
      setSpinner(false);
    }
  };
  const handleAccept = async (value, id) => {
    setSpinner(true);
    try {
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}admin/update_request_status`,
        {
          status: value,
          request_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response;

      if (value == "accepted") {
        toast.success("Request Accepted successfully:");
      } else {
        toast.error("Request Rejected successfully:");
      }

      setModalShow(false);
      fetchUsers();
      setSpinner(false);
      console.log(data);
    } catch (error) {
      setError("Error Request", error);
      setSpinner(false);
    }
  };
  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="common-bg">
        <div className="container-fluid">
          <div className="common-section">
            <div className="custom-table-header">
              <h1>List of Booking</h1>
            </div>
            <div className="filter-wrapper mt-4 mb-3">
              <div className="filter-wrapper-left">
                <div className="search-field">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <img src={SearchIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="table-body">
              <div className="custom-table">
                <div className="table-header-row">
                  <div className="table-cell">User</div>
                  <div className="table-cell">Building</div>
                  <div className="table-cell">Request Date</div>
                  <div className="table-cell">Request Time</div>
                  <div className="table-cell">Floor</div>
                  <div className="table-cell">Space</div>
                  <div className="table-cell">Status</div>
                  <div className="table-cell"></div>
                </div>
                {userRequestData && userRequestData.length > 0 ? (
                  userRequestData.map((request) => (
                    <div className="table-row">
                      <div className="table-cell" data-heading="User">
                        <div className="table-cell-inner">
                          {request?.user?.username || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Building">
                        <div className="table-cell-inner">
                          {request?.building?.building_name || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Request Date">
                        <div className="table-cell-inner">
                          {request?.created_date || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Request Time">
                        <div className="table-cell-inner">
                          {request?.created_at || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Floor">
                        <div className="table-cell-inner">
                          {request?.floor?.floor_name || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Space">
                        <div className="table-cell-inner">
                          {request?.space?.space_name || "-"}
                        </div>
                      </div>
                      <div className="table-cell" data-heading="Status">
                        <div
                          className={`table-cell-inner status ${request.status}`}
                        >
                          {request?.status || "-"}
                        </div>
                      </div>
                      <div
                        className="table-cell action-dropdown"
                        data-heading="Action:"
                      >
                        <div className="table-cell-inner ">
                          <img
                            className="table-action-btn"
                            src={DotIcon}
                            alt="dots"
                            onClick={() => toggleDropdown(request.id)}
                          />
                        </div>
                        {activeDropdown === request.id && (
                          <div
                            ref={dropdownRef}
                            className="table-action-dropdown"
                          >
                            <button
                              onClick={() => viewRequestDetail(request.id)}
                            >
                              View
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data-message">No data available</div>
                )}
              </div>
            </div>
            {totalPageCount > 1 && (
              <Pagination
                totalPageCount={totalPageCount}
                currentPage={pageNumber}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      {/* modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modal-header pb-0">
          <Button
            className="modal-close-button"
            onClick={() => setModalShow(false)}
          >
            <img src={ModalCloseBtn} alt="image" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0 booking-panel-modal">
          <div className="moda-body-content">
            <div className="modal-content-header">
              <img src={BuildingIconGreen} alt="image" />
              <h1>See the Booked Floor</h1>
              <p>
                We are just showing here the <strong>details of</strong> Booking{" "}
                <strong>Floor</strong>.
              </p>
            </div>
            {selectedRequest && (
              <form>
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <div className="mb-3 modal-form-field edit-action __readyonly ">
                      <h1>User Details</h1>
                      <NavLink>
                        <img src={EditIcon} alt="" />
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3 modal-form-field __readyonly ">
                      <label>User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={selectedRequest?.building?.building_name || "-"}
                        readOnly
                        placeholder="Enter Building Name (if applicable)"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3 modal-form-field __readyonly">
                      <label>Building Name</label>

                      <input
                        type="text"
                        className="form-control"
                        value={selectedRequest?.building?.building_name || "-"}
                        readOnly
                        placeholder="Enter Building Name (if applicable)"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {" "}
                    <div className="mb-3 modal-form-field __readyonly">
                      <label>Start Date</label>
                      <input
                        type="text"
                        className="form-control"
                        name="checkindate"
                        value={selectedRequest.checkin_date}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3 modal-form-field __readyonly">
                      <label>Start Time</label>
                      <input
                        type="text"
                        className="form-control"
                        name="checkintime"
                        value={selectedRequest.checkin_time}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3 modal-form-field __readyonly">
                      <label>End Date</label>
                      <input
                        type="text"
                        className="form-control"
                        name="checkoutdate"
                        value={selectedRequest.checkout_date}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {" "}
                    <div className="mb-3 modal-form-field __readyonly">
                      <label>End Time</label>
                      <input
                        type="text"
                        className="form-control"
                        name="checkouttime"
                        value={selectedRequest.checkout_time}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 ">
                    {" "}
                    <div className="mb-3 modal-form-field __readyonly total-time-wrapper">
                      <label>Total Time</label>
                      <div className="total-time">
                        {" "}
                        <input type="text" value={"48 hours"} readOnly />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookingPanel;
