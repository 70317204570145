import React from "react";
import LoaderImg from "../../../assets/images/loader.png";
const Loader = () => {
  return (
    <div class="loader">
      <img src={LoaderImg} alt="" />
      <span></span>
      <span></span>
    </div>
  );
};

export default Loader;
