import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "../../../assets/images/search-icon.png";
import AddIcon from "../../../assets/images/add-icon.png";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import AddFloor from "./addFloor/addFloor";
import EditIcon from "../../../assets/images/edit.png";
import SettingIcon from "../../../assets/images/setting.png";
import DeleteIcon from "../../../assets/images/delete.png";
import UpdateFloor from "./addFloor/updateFloor";
import Pagination from "../../common/pagination/pagination";
import DotIcon from "../../../assets/images/dots-horizontal-icon.png";
const FloorList = ({ setSpinner, toast, fetchBuildings }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [floor, setFloor] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const fetchFloors = async (query = "") => {
    try {
      const token = localStorage.token;
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}admin/get_all_floors?building_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: pageNumber,
            search: query,
          },
        }
      );
      const data = response.data.data.floors.data;
      setTotalPages(response.data.data.floors.last_page);
      setFloor(data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching floors:", error);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPageNumber(1);
  };
  useEffect(() => {
    fetchFloors(searchQuery);
  }, [pageNumber, searchQuery]);
  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  const handleUpdateModalShow = (data) => {
    setSelectedFloor(data);
    setUpdateModalShow(true);
  };
  const handleDelete = async (id) => {
    try {
      const token = localStorage.token;
      setSpinner(true);
      const response = await fetch(
        `${BASE_URL}admin/delete_floor?floor_id=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete floor");
      }
      const responseData = await response.json();
      setSpinner(false);
      toast.success(responseData.message);
      fetchFloors();
      fetchBuildings();
    } catch (error) {
      setSpinner(false);
      toast.error("Failed to floor building.");
    }
  };
  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="common-section mt-4">
        <div className="custom-table-header">
          <h1>List of all Floors</h1>
        </div>
        <div className="filter-wrapper mt-4 mb-3">
          <div className="filter-wrapper-left">
            <div className="search-field">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <img src={SearchIcon} alt="" />
            </div>
          </div>
          <div className="filter-wrapper-right">
            <div className="filter-button-wrapper">
              <button className="add-user" onClick={() => setModalShow(true)}>
                <img src={AddIcon} alt="" /> Add New Floor
              </button>
            </div>
          </div>
        </div>
        <div className="table-body">
          <div className="custom-table overflow-hidden">
            <div className="table-header-row">
              <div className="table-cell">Floor Name</div>
              <div className="table-cell text-md-center">Floor Number</div>
              <div className="table-cell"></div>
            </div>
            {floor && floor.length > 0 ? (
              floor.map((data) => (
                <div className="table-row" key={data.id}>
                  <div className="table-cell" data-heading="Floor Name:">
                    <div className="table-cell-inner">{data.floor_name}</div>
                  </div>
                  <div
                    className="table-cell justify-content-md-center"
                    data-heading="Floor Number:"
                  >
                    <div className="table-cell-inner"> {data.floor_number}</div>
                  </div>

                  <div
                    className="table-cell action-dropdown"
                    data-heading="Action:"
                  >
                    <div className="table-cell-inner ">
                      <img
                        className="table-action-btn"
                        src={DotIcon}
                        alt="dots"
                        onClick={() => toggleDropdown(data.id)}
                      />
                    </div>
                    {activeDropdown === data.id && (
                      <div ref={dropdownRef} className="table-action-dropdown">
                        <NavLink to={`/building/floor/space/${data.id}`}>
                          <button>Setting</button>
                        </NavLink>

                        <button onClick={() => handleUpdateModalShow(data)}>
                          Edit
                        </button>
                        <button onClick={() => handleDelete(data.id)}>
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data-message">No data available</div>
            )}
          </div>
        </div>
        {totalPageCount > 1 && (
          <Pagination
            totalPageCount={totalPageCount}
            currentPage={pageNumber}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      {/* modal */}
      <AddFloor
        show={modalShow}
        onHide={() => setModalShow(false)}
        fetchFloors={fetchFloors}
        setSpinner={setSpinner}
        toast={toast}
        fetchBuildings={fetchBuildings}
      />
      {selectedFloor && (
        <UpdateFloor
          show={updateModalShow}
          onHide={() => setUpdateModalShow(false)}
          floor={selectedFloor}
          setSpinner={setSpinner}
          toast={toast}
          fetchFloors={fetchFloors}
        />
      )}
    </>
  );
};
export default FloorList;
