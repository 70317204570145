import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalCloseBtn from "../../../../assets/images/modal-close-btn.png";
import BuildingIconGreen from "../../../../assets/images/building-modal-icon.png";
import EditIcon from "../../../../assets/images/edit-icon.png";
const BookedDetail = ({ show, onHide, setSpinner }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal-header pb-0">
        <Button className="modal-close-button" onClick={onHide}>
          <img src={ModalCloseBtn} alt="image" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="moda-body-content">
          <div className="modal-content-header">
            <img src={BuildingIconGreen} alt="image" />
            <h1>See the Booked Floor</h1>
            <p>
              We are just showing here the <strong>details</strong> of
              <strong> Booking Floor </strong>.
            </p>
          </div>
          <div className="book-detail-header mt-3">
            <h1>User Details</h1>
            <button>
              {" "}
              <img src={EditIcon} alt="" />
            </button>
          </div>
          <div className="book-detail-content mt-2">
            <div className="__book-detail">
              <p className="__detail-heading">User Name</p>
              <p className="__detail-value">John Deo</p>
            </div>
            <div className="__book-detail">
              <p className="__detail-heading">Building Name</p>
              <p className="__detail-value">Lorem Ipsum</p>
            </div>
            <div className="__book-detail">
              <p className="__detail-heading">Start Date</p>
              <p className="__detail-value">Dec, 12 2023</p>
            </div>
            <div className="__book-detail">
              <p className="__detail-heading">End Date</p>
              <p className="__detail-value">Dec, 14 2023</p>
            </div>
            <div className="__book-detail">
              <p className="__detail-heading">Start Time</p>
              <p className="__detail-value">12:00 PM</p>
            </div>
            <div className="__book-detail">
              <p className="__detail-heading">End Time</p>
              <p className="__detail-value">01:00 PM</p>
            </div>
            <div className="__book-detail __total">
              <p className="__detail-heading">Total Time</p>
              <p className="__detail-value">
                <span>48 Hours</span>
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BookedDetail;
