import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FloorBanner from "./floorBanner";
import FloorList from "./floorList";
import Loader from "../../common/loader/loader";
const FloorIndex = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [building, setBuilding] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const fetchBuildings = async () => {
    try {
      const token = localStorage.token;
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}admin/building_details?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.building;
      setBuilding(data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching buildings:", error);
    }
  };
  useEffect(() => {
    fetchBuildings();
  }, []);
  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="common-bg">
        <div className="container-fluid">
          <FloorBanner building={building} setSpinner={setSpinner} />
          <FloorList
            setSpinner={setSpinner}
            toast={toast}
            fetchBuildings={fetchBuildings}
          />
        </div>
      </div>
    </>
  );
};

export default FloorIndex;
