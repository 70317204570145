import { configureStore } from "@reduxjs/toolkit";
import buildingsReducer from "../slices/buildingsSlice";
import spacesReducer from "../slices/spacesSlice";
import requestReducer from "../slices/requestSlice";
import buildingGraphReducer from "../slices/buildingGraphSlice";
import spaceGraphReducer from "../slices/spaceGraphSlice";
import userRegisterReducer from "../slices/userRegisterSlice";
import buildingNameReducer from "../slices/buildingNameSlice";
import floorNameReducer from "../slices/floorNameSlice";
import roomNameReducer from "../slices/roomNameSlice";
import roomUtilizationReducer from "../slices/roomUtilizationSlice";
const store = configureStore({
  reducer: {
    buildings: buildingsReducer,
    spaces: spacesReducer,
    requests: requestReducer,
    buildingGraph: buildingGraphReducer,
    spaceGraph: spaceGraphReducer,
    signup: userRegisterReducer,
    buildingName: buildingNameReducer,
    floorName: floorNameReducer,
    roomName: roomNameReducer,
    roomUtilization: roomUtilizationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          BASE_URL: process.env.REACT_APP_BASE_URL,
        },
      },
    }),
});

export default store;
