import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactApexChart from "react-apexcharts";
const RoomUtilization = () => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleStartDateChange = (date) => {
    const startOfWeek = moment(date).toDate();
    const endOfWeek = moment(date).add(6, "days").toDate();
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };
  const toggleDatePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };
  const datePickerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const chartData = {
    series: [
      {
        name: "Room utilization",
        data: [44, 95, 57, 56, 81, 58, 93],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          endingShape: "rounded",
          borderRadius: 5,
        },
      },
      colors: "#0E9FB5",
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        markers: {
          fillColors: ["#4A3AFF", "#C893FD"],
        },
      },
    },
  };
  return (
    <div className="roomUtilization-col">
      <div className="space-analytics-common-wrapper">
        <div className="space-analytics-header">
          <h1>
            <span>Statistics</span>Meeting Room Utilization By Day
          </h1>
          <div className="date-wrapper mt-3">
            <p>
              Present {moment(startDate).format("MMMM D")} -{" "}
              {moment(endDate).format("MMMM D")}
            </p>
            <div className="range-picker-wrapper">
              <button className="date-toggle" onClick={toggleDatePicker}>
                Date Range
                <KeyboardArrowDownIcon />
              </button>
              {isPickerOpen && (
                <div className="range-picker" ref={datePickerRef}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsStart
                    inline
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={250}
        />
      </div>
    </div>
  );
};

export default RoomUtilization;
