import React from "react";
import ReactApexChart from "react-apexcharts";

const BuildingBarChart = ({buildingGraph}) => {
  const buildingData = [
    { month: "JAN", value: 100 },
    { month: "FEB", value: 90 },
    { month: "MAR", value: 80 },
    { month: "APR", value: 80 },
    { month: "MAY", value: 60 },
    { month: "JUN", value: 50 },
    { month: "JUL", value: 40 },
    { month: "AUG", value: 30 },
    { month: "SEP", value: 50 },
    { month: "OCT", value: 60 },
    { month: "NOV", value: 30 },
    { month: "DEC", value: 20 },
  ];
  const seriesData = [
    {
      month: "Building Value",
      data: buildingGraph.map((building) => ({
        x: building.month,
        y: building.value,
      })),
    },
  ];
  const options = {
    series: seriesData,
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
      type: "bar",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
        borderRadius: 5,
      },
    },

    colors: [
      function ({ value, seriesIndex, w }) {
        if (value === 100) {
          return "#D12F2F";
        } else if (value >= 90) {
          return "#D12F2F";
        } else if (value >= 40) {
          return "#FFBF42";
        } else {
          return "#5CA044";
        }
      },
    ],
  };
  return (
    <div className="chart-wrapper">
      <div className="chart-heading">
        <h1>
          <span>Statistics</span>Buildings
        </h1>
        <div className="chart-status">
          <h1>
            <div className="status-box low"></div>Fully Reserved
          </h1>
          <h1>
            <div className="status-box medium"></div>Average
          </h1>
          <h1>
            <div className="status-box top"></div>Free
          </h1>
        </div>
      </div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={options.series}
          type="bar"
          height={280}
        />
      </div>
    </div>
  );
};

export default BuildingBarChart;
