import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialData = {
  loading: false,
  user: null,
  error: null,
  userRegister: false,
};

export const userRegistration = createAsyncThunk(
  "signup/userRegistration",
  async (submitData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}register`, submitData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const userRegisterSlice = createSlice({
  name: "signup",
  initialState: initialData,
  reducers: {
    resetUserRegister: (state) => {
      state.userRegister = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userRegistration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userRegistration.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.userRegister = true;
      })
      .addCase(userRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { resetUserRegister } = userRegisterSlice.actions;
export default userRegisterSlice.reducer;
