import React, { useState, useEffect } from "react";
import Logo from "../../../assets/images/logo.png";
import BellIcon from "../../../assets/images/bell-icon.png";
import Profile from "../../../assets/images/profile-img.png";
import ArrowIcon from "../../../assets/images/arrow-down-icon.png";
import ArrowTopIcon from "../../../assets/images/arrow-top-right-icon.png";
import VerifiedIcon from "../../../assets/images/verified-icon.png";
import MenuIcon from "../../../assets/images/hamburger.png";
import CloseMenuIcon from "../../../assets/images/close.png";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import NavbarData from "./navbarData";
const Navbar = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    if (authType) {
      switch (authType.type) {
        case "owner":
          navigate("/owner-login");
          break;
        case "admin":
          navigate("/admin-login");
          break;
        case "user":
          navigate("/user-login");
          break;
        default:
          navigate("/");
          break;
      }
    } else {
      navigate("/");
    }
  };
  const [customDropDown, setCustomDropDown] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [pageName, setPageName] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    const userTypeFromStorage = localStorage.getItem("user");
    const authType = JSON.parse(userTypeFromStorage);
    if (authType) {
      setUserType(authType.type);
    }
  }, []);
  useEffect(() => {
    const pathname = location.pathname;
    const lastValue = pathname.lastIndexOf("/");
    const currentPageName = pathname.slice(lastValue + 1);
    const pathNumber = !isNaN(currentPageName);
    const getpreviousPathname = pathname.split("/");
    const previousPath = getpreviousPathname[getpreviousPathname.length - 2];

    if (pathNumber === true) {
      setPageName(previousPath);
    } else {
      setPageName(currentPageName);
    }
  }, [location.pathname]);
  const handleCustomDropDown = (event) => {
    event.preventDefault();
    setCustomDropDown(!customDropDown);
  };
  const handleOpenMenu = (event) => {
    event.preventDefault();
    setOpenMenu(!openMenu);
  };
  const userAuth = localStorage.getItem("user");
  const authType = JSON.parse(userAuth);
  let profileImage = authType ? authType.image : null;
  let profileName = authType ? authType.username : null;
  return (
    <div className="container-fluid navbar-section">
      <div className="navbar-top-section">
        <div className="logo-wrapper">
          <img src={Logo} alt="image" />
        </div>
        <div className="navbar-content">
          <div className="navbar-content-left">
            <div className="page-name">
              <h1>{pageName}</h1>
            </div>
          </div>
          <div className="navbar-content-right">
            <div className="navbar-btn-wrapper">
              <div className="notification-icon d-none">
                <img
                  src={BellIcon}
                  alt="image"
                  onClick={handleCustomDropDown}
                />
                <div className="notification-counter">
                  <label>12</label>
                </div>
                <div
                  className={`notification-wrapper ${
                    customDropDown ? "open" : ""
                  }`}
                >
                  <ul>
                    <li>
                      <img src={Profile} alt="image" />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </li>
                    <li>
                      <img src={Profile} alt="image" />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </li>
                    <li>
                      <img src={Profile} alt="image" />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </li>
                    <li>
                      <img src={Profile} alt="image" />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </li>
                    <li>
                      <img src={Profile} alt="image" />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </li>
                    <li>
                      <img src={Profile} alt="image" />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="navbar-btn ">
                <button>
                  <img src={ArrowTopIcon} alt="image" />
                </button>
              </div> */}
              <div className="menu-btn">
                <button>
                  <img
                    src={openMenu ? CloseMenuIcon : MenuIcon}
                    alt="image"
                    onClick={handleOpenMenu}
                  />
                </button>
              </div>
            </div>

            <div className="profile-wrapper">
              <div className="profile-image">
                <img className="profile-img" src={profileImage} alt="image" />
                <img
                  className="profile-status"
                  src={VerifiedIcon}
                  alt="image"
                />
              </div>
              <div className="profile-name">
                <h1>{profileName}</h1>
              </div>
              {/* <div className="profile-logout">
                <img src={ArrowIcon} alt="image" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={`sidebar ${openMenu ? "open" : ""}`}>
        <div className="profile-wrapper pb-2">
          <div className="profile-image">
            <img className="profile-img" src={profileImage} alt="image" />
            <img className="profile-status" src={VerifiedIcon} alt="image" />
          </div>
          <div className="profile-name">
            <h1>{profileName}</h1>
          </div>
        </div>
        {NavbarData.map((data, index) => (
          <ul key={index}>
            {data.visibility && data.visibility.includes(userType) && (
              <li className="navbar-title">{data.navbarTitle}</li>
            )}

            {data.navbarLinks
              .filter((link) =>
                link.visibility ? link.visibility.includes(userType) : true
              )
              .map((link, index) => (
                <li key={index} className="navbar-link">
                  {link.to === "/" ? (
                    <button onClick={handleLogout}>
                      <span dangerouslySetInnerHTML={{ __html: link.icon }} />
                      <span>
                        {link.linkName}
                        {link.subLinkName && <span>{link.subLinkName}</span>}
                      </span>
                    </button>
                  ) : (
                    <NavLink
                      to={link.to}
                      className={({ isActive }) =>
                        `${link.customClass ? link.customClass : ""} ${
                          isActive ? "active-menu" : ""
                        }`
                      }
                    >
                      <span dangerouslySetInnerHTML={{ __html: link.icon }} />
                      <span>
                        {link.linkName}
                        {link.subLinkName && <span>{link.subLinkName}</span>}
                      </span>
                    </NavLink>
                  )}
                </li>
              ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
