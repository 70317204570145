import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchIcon from "../../../assets/images/search-icon.png";
import Loader from "../../common/loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FloorIcon from "../../../assets/images/floor-number-icon.png";
import { NavLink } from "react-router-dom";
import Pagination from "../../common/pagination/pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchBuildings,
  setSearchQuery,
  setPageNumber,
} from "../../../slices/buildingsSlice";
const BuildingList = () => {
  const { buildings, loading, totalPageCount, searchQuery, pageNumber } =
    useSelector((state) => state.buildings);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBuildings({ pageNumber, searchQuery }));
  }, [dispatch, pageNumber, searchQuery]);

  const handleSearchChange = (e) => {
    dispatch(setSearchQuery(e.target.value));
  };
  const handlePageChange = (newPageNumber) => {
    dispatch(setPageNumber(newPageNumber));
  };

  return (
    <>
      {loading && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="common-bg">
        <div className="container-fluid">
          <div className="common-section">
            <div className="custom-table-header">
              <h1>List of all Buildings</h1>
            </div>
            <div className="filter-wrapper mt-4 mb-3">
              <div className="filter-wrapper-left">
                <div className="search-field">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <img src={SearchIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="building-card-wrapper mt-4">
              <div className="row">
                {buildings.length === 0 && (
                  <div className="col-12">
                    <div className="no-data-message">
                      No buildings available.
                    </div>
                  </div>
                )}
                {buildings.map((building) => (
                  <div
                    className="col-md-6 col-xl-4 col-xxl-3 mb-3"
                    key={building.id}
                  >
                    <div className="building-card">
                      <div className="building-card-header">
                        <div className="building-img-wrapper">
                          {building.files && building.files.length > 0 && (
                            <img src={building.files[0]} alt="" />
                          )}
                        </div>
                        <div className="building-onwership">
                          <img src={building.owner.image} alt="" />
                          <h1>
                            {building.owner.username}{" "}
                            <span>
                              {building.owner.total_buildings} buildings
                            </span>
                          </h1>
                        </div>
                        <div className="building-detail">
                          <h1>
                            {building.building_name}
                            <span>{building.location}</span>
                          </h1>
                          <h2>
                            <img src={FloorIcon} alt="" />{" "}
                            <span>{building.total_floors}</span> Floors
                          </h2>
                        </div>
                      </div>
                      <div className="building-card-footer">
                        <div className="building-card-footer-top">
                          <NavLink
                            className="detail-btn"
                            to={`/all-building/details/${building.id}`}
                          >
                            View Details
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {totalPageCount > 1 && (
                <Pagination
                  totalPageCount={totalPageCount}
                  currentPage={pageNumber}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildingList;
