import React, { useState, useEffect } from "react";
import Logo from "../../../assets/images/logo.png";
import UserIcon from "../../../assets/images/user-icon.png";
import PasswordIcon from "../../../assets/images/pasword-icon.png";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { axiosPostCall } from "../../../api/auth/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/common/loader/loader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };
  const [user, setUser] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);
  const [spinner, setSpinner] = useState(false);
  let navigation = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const pathLastValue = pathname.lastIndexOf("/");
  const currentPageName = pathname.slice(pathLastValue + 1);

  let apiEndPoint = false;
  if (currentPageName === "admin-login") {
    apiEndPoint = "login";
  } else if (currentPageName === "owner-login") {
    apiEndPoint = "owner-login";
  } else {
    apiEndPoint = "user-login";
  }
  const userchange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSpinner(true);
      const endpoint = apiEndPoint;
      const userData = await axiosPostCall(endpoint, user);
      localStorage.setItem("token", userData.data.token);
      const authUser = JSON.stringify(userData.data.user);
      localStorage.setItem("user", authUser);
      toast.success("Login successfully!", {
        onClose: () => {
          if (apiEndPoint === "login") {
            navigation("/dashboard");
          } else if (apiEndPoint === "owner-login") {
            navigation("/dashboard");
          } else {
            navigation("/user-dashboard");
          }
        },
      });

      setSpinner(false);
      setError(null);
    } catch (error) {
      const errorContent = error.response.data.message;
      setError(errorContent);
      setSpinner(false);
    }
  };

  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="container-fluid auth-section">
        <div className="auth-section-banner">
          <div className="auth-section-banner-content">
            <h1>Enter your Email and Password for login to Space Management</h1>
            <p>
              Enter your Email and Password for login to Space Management Enter
              your Email and Password for login to Space ManagementEnter your
              Email and Password for login to Space Management
            </p>
          </div>
        </div>
        <div className="auth-section-form">
          <div className="auth-section-form-content">
            <div className="auth-logo">
              <img src={Logo} alt="logo" />
              <p>Enter your Email and Password for login to Space Management</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="form-heading">
                  <h1>Sign In</h1>
                </div>
              </div>
              {error && (
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="mb-4 form-field ">
                <div className="form-field-icon">
                  <input
                    type="email"
                    name="email"
                    onChange={userchange}
                    className="form-control"
                    placeholder="User Name"
                    value={user.email}
                  />
                  <img className="icon-left" src={UserIcon} alt="logo" />
                </div>
              </div>

              <div className="mb-4 form-field ">
                <div className="form-field-icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    onChange={userchange}
                    value={user.password}
                  />
                  <img className="icon-left" src={PasswordIcon} alt="logo" />
                  {!showPassword ? (
                    <VisibilityOutlinedIcon
                      className="eye-icon"
                      onClick={handlePassword}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      className="eye-icon"
                      onClick={handlePassword}
                    />
                  )}
                </div>
              </div>
              <div className="checkbox-wrapper mb-4">
                <div class=" form-check form-field">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                  />
                  <label class="form-check-label" for="exampleCheck1">
                    Remember Me
                  </label>
                </div>
                <NavLink to={"/forgot-password"}>Forget Password?</NavLink>
              </div>

              <div className="form-field">
                <button type="submit">Log In</button>
              </div>
              {apiEndPoint === "user-login" && (
                <div className="form-field mt-2">
                  <p>
                    If you have no acount{" "}
                    <NavLink to={"/user-signup"}>create one</NavLink>
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
