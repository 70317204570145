import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = {
  loading: false,
  roomUtilizationData: [],
  error: null,
};

export const fetchRoomUtilization = createAsyncThunk(
  "roomUtilization/fetchRoomUtilization",
  async ({ roomId, year, month }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}admin/room_utilization`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          room_id: roomId,
          month: month,
          year: year,
        },
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

const roomUtilizationSlice = createSlice({
  name: "roomUtilization",
  initialState,
  reducers: {
    clearRoomUtilization: (state) => {
      state.roomUtilizationData = [];
    },
    resetRoomUtilization: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomUtilization.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoomUtilization.fulfilled, (state, action) => {
        state.loading = false;
        state.roomUtilizationData = action.payload;
      })
      .addCase(fetchRoomUtilization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { clearRoomUtilization, resetRoomUtilization } =
  roomUtilizationSlice.actions;
export default roomUtilizationSlice.reducer;
