import { useState } from "react";
import Filters from "../roomUtilization/filters";
import UtilizationChart from "../roomUtilization/utilizationChart";
import { useBuildingData } from "../common/useBuildingData";
import Loader from "../../common/loader/loader";
import UtilizationSemiDonutChart from "../common/utilizationSemiDonutChart/utilizationSemiDonutChart";
const BuildingCorporate = () => {
  const {
    loading,
    error,
    buildingNames,
    floorNames,
    roomNames,
    roomUtilizationData,
    selectedRoomName,
    handleBuildingChange,
    handleFloorChange,
    handleRoomChange,
  } = useBuildingData();

  const { utilization } = roomUtilizationData || [];
  const utilizationValue = utilization || 0;
  const utilizationStyle = {
    "--percentage": `${utilizationValue}`,
  };
  const [viewType, setViewType] = useState("weekly");

  const weeklyData = [
    { day: "Su", value: 100 },
    { day: "Mo", value: 70 },
    { day: "Tu", value: 90 },
    { day: "We", value: 80 },
    { day: "Th", value: 70 },
    { day: "Fr", value: 85 },
    { day: "Sa", value: 50 },
  ];

  const monthlyData = [
    { day: "Week1", value: 40 },
    { day: "Week2", value: 0 },
    { day: "Week3", value: 60 },
    { day: "Week4", value: 90 },
  ];

  const occupancyData = viewType === "weekly" ? weeklyData : monthlyData;

  const maxValue = Math.max(...occupancyData.map((item) => item.value));
  const averageValue =
    occupancyData.reduce((sum, item) => sum + item.value, 0) /
    occupancyData.length;

  const getStatusClass = (value) => {
    if (value === maxValue) {
      return "full-occupied";
    } else if (value >= averageValue) {
      return "medium-occupied";
    } else {
      return "full-free";
    }
  };

  const handleViewChange = (e) => {
    setViewType(e.target.value);
  };
  return (
    <>
      {loading && <Loader />}{" "}
      <div className="common-bg">
        <div className="container-fluid">
          {error && (
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="common-section building-corporate-section">
            <div className="custom-table-header">
              <h1>Building Carporate</h1>
              <div className="widget-wrapper">
                <div className="select-wrappper widget-col">
                  <select className="form-control">
                    <option selected disabled>
                      All Widgets
                    </option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                  <svg
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#24CCA7"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <Filters
              handleBuildingChange={handleBuildingChange}
              buildingNames={buildingNames}
              handleFloorChange={handleFloorChange}
              floorNames={floorNames}
              handleRoomChange={handleRoomChange}
              roomNames={roomNames}
            />
          </div>
          <div className="building-corporate-graph mt-3">
            <div className="corporate-graph-col">
              {" "}
              <UtilizationChart
                utilizationStyle={utilizationStyle}
                utilizationValue={utilizationValue}
                customClass="d-none"
                selectedRoomName={selectedRoomName}
              />
            </div>
            <div className="corporate-graph-col">
              <div className="graph-heading">
                <h1>
                  <span>Statistics</span> Peek Occupancy Percentage
                </h1>
              </div>
              <div className="chart-status">
                <h1>
                  <div className="status-box full-occupied"></div>Highest
                  Occupied
                </h1>
                <h1>
                  <div className="status-box medium-occupied"></div>Average
                  Occupied
                </h1>
                <h1>
                  <div className="status-box full-free"></div>Free
                </h1>
              </div>
              <div className="corporate-graph-filter">
                <h1>
                  All Days<span>Working Days</span>
                </h1>
                <div className="select-wrappper widget-col">
                  <select className="form-control" onChange={handleViewChange}>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                  <svg
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#24CCA7"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="corporate-graph-card-wrapper">
                {occupancyData.map((item, index) => (
                  <div className="corporate-graph-card" key={index}>
                    <div
                      className={`corporate-graph-box ${getStatusClass(
                        item.value
                      )}`}
                    ></div>
                    <label>{item.day}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <UtilizationSemiDonutChart />
        </div>
      </div>
    </>
  );
};

export default BuildingCorporate;
