import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalCloseBtn from "../../../../assets/images/modal-close-btn.png";
import BuildingIconGreen from "../../../../assets/images/building-modal-icon.png";
import AddFile from "./fileUpload/dropZone";
const AddFloor = ({
  show,
  onHide,
  fetchFloors,
  setSpinner,
  toast,
  fetchBuildings,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [imageData, setImageData] = useState([]);
  const [error, setError] = useState(null);
  const [floorData, setFloorData] = useState({
    floor_name: "",
    floor_number: "",
    svg_code: "",
  });

  useEffect(() => {
    if (!show) {
      setError(null);
    }
  }, [show]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFloorData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleShowImageData = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === "image/svg+xml") {
        const reader = new FileReader();
        reader.onload = (event) => {
          console.log(event.target.result,"code");
          setFloorData((prevState) => ({
            ...prevState,
            svg_code: event.target.result,
          }));
         
        };
        reader.readAsText(file);
      } else {
        setError("Please upload a valid SVG file.");
      }
      setImageData(files);
    }
  };
  const uploadImages = async () => {
    try {
      const formData = new FormData();
      imageData.forEach((file) => {
        formData.append("files[]", file);
      });
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}user/upload_files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      setError("Error uploading images:", error);
      throw error;
    }
  };
  const handleAddFloor = async () => {
    setSpinner(true);

    try {
      const imageUrls = await uploadImages();
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}admin/floor`,
        {
          ...floorData,
          files_data: imageUrls,
          building_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onHide();
      toast.success("Floor added Successfully");
      fetchFloors();
      fetchBuildings();
      setError(null);
      setSpinner(false);
      setFloorData({
        floor_name: "",
        floor_number: "",
        svg_code: "",
      });
    } catch (error) {
      setError(error.response.data.message);
      setSpinner(false);
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal-header pb-0">
        <Button className="modal-close-button" onClick={onHide}>
          <img src={ModalCloseBtn} alt="image" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="moda-body-content">
          <div className="modal-content-header">
            <img src={BuildingIconGreen} alt="image" />
            <h1>Add New Floor</h1>
            <p>
              Please fill the Information to <strong>Add</strong> New{" "}
              <strong>Floor</strong>.
            </p>
          </div>
          <form>
            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="mb-3 modal-form-field">
              <AddFile onShowImageData={handleShowImageData} />
            </div>
            <div className="mb-3 modal-form-field">
              <label>Floor Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Floor Name"
                name="floor_name"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 modal-form-field">
              <label>Floor Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Location Here"
                name="floor_number"
                onChange={handleChange}
              />
            </div>
            {/* <div className="mb-3 modal-form-field">
              <label>Floor Svg Code</label>

              <textarea
                onChange={handleChange}
                className="form-control"
                name="svg_code"
                placeholder="Paste SVG code here..."
              />
            </div> */}

            <div className="mb-3 modal-form-field-btn">
              <button onClick={onHide}>Cancel</button>
              <button type="button" onClick={handleAddFloor}>
                Add New Floor
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddFloor;
