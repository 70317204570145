import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBuildingNames,
  setSelectedBuilding,
} from "../../../slices/buildingNameSlice";
import {
  fetchFloorNames,
  setSelectedFloor,
  clearFloors,
} from "../../../slices/floorNameSlice";
import {
  fetchRoomNames,
  setSelectedRoom,
  clearRooms,
  setSelectedRoomName,
} from "../../../slices/roomNameSlice";
import {
  fetchRoomUtilization,
  clearRoomUtilization,
} from "../../../slices/roomUtilizationSlice";

export const useBuildingData = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const dispatch = useDispatch();

  const {
    loading: loadingBuildings,
    buildingNames,
    error: buildingError,
    selectedBuilding,
  } = useSelector((state) => state.buildingName);
  const {
    loading: loadingFloors,
    floorNames,
    error: floorError,
    selectedFloor,
  } = useSelector((state) => state.floorName);
  const {
    loading: loadingRooms,
    roomNames,
    error: roomError,
    selectedRoom,
    selectedRoomName,
  } = useSelector((state) => state.roomName);
  const {
    loading: loadingRoomUtilization,
    roomUtilizationData,
    error: roomUtilizationError,
  } = useSelector((state) => state.roomUtilization);

  useEffect(() => {
    dispatch(fetchBuildingNames());
  }, [dispatch]);

  useEffect(() => {
    if (buildingNames.length > 0) {
      const firstBuildingId = buildingNames[0].id;
      dispatch(setSelectedBuilding(firstBuildingId));
    }
  }, [buildingNames, dispatch]);

  useEffect(() => {
    if (floorNames.length > 0) {
      const firstFloorId = floorNames[0].id;
      dispatch(setSelectedFloor(firstFloorId));
    }
  }, [floorNames, dispatch]);

  useEffect(() => {
    if (roomNames.length > 0) {
      const firstRoomId = roomNames[0].id;
      dispatch(setSelectedRoom(firstRoomId));
    }
  }, [roomNames, dispatch]);

  useEffect(() => {
    if (selectedBuilding) {
      dispatch(fetchFloorNames(selectedBuilding));
      dispatch(setSelectedFloor(null));
      dispatch(setSelectedRoom(null));
      dispatch(clearFloors());
      dispatch(clearRooms());
      dispatch(clearRoomUtilization());
    }
  }, [selectedBuilding, dispatch]);

  useEffect(() => {
    if (selectedFloor) {
      dispatch(fetchRoomNames(selectedFloor));
      dispatch(setSelectedRoom(null));
      dispatch(clearRoomUtilization());
    }
  }, [selectedFloor, dispatch]);

  useEffect(() => {
    if (selectedRoom) {
      const [year, month] = selectedDate
        ? [
            new Date(selectedDate).getFullYear(),
            new Date(selectedDate).getMonth() + 1,
          ]
        : [new Date().getFullYear(), new Date().getMonth() + 1];
      dispatch(fetchRoomUtilization({ roomId: selectedRoom, year, month }));
      const room = roomNames.find((room) => room.id === selectedRoom);
      if (room) {
        dispatch(setSelectedRoomName(room.space_name));
      }
    }
  }, [selectedRoom, selectedDate, roomNames, dispatch]);
  const handleBuildingChange = (e) => {
    const buildingId = e.target.value;
    dispatch(setSelectedBuilding(buildingId));
  };
  const handleFloorChange = (e) => {
    const floorId = e.target.value;
    dispatch(setSelectedFloor(floorId));
  };

  const handleRoomChange = (e) => {
    const roomId = e.target.value;
    const room = roomNames.find((room) => room.id == roomId);
    if (room) {
      dispatch(setSelectedRoomName(room.space_name));
    }
    dispatch(setSelectedRoom(roomId));
  };
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  return {
    loading:
      loadingBuildings ||
      loadingFloors ||
      loadingRooms ||
      loadingRoomUtilization,
    error: buildingError || floorError || roomError || roomUtilizationError,
    buildingNames,
    floorNames,
    roomNames,
    selectedRoomName,
    roomUtilizationData,
    handleBuildingChange,
    handleFloorChange,
    handleRoomChange,
    handleDateChange,
    selectedDate,
  };
};
