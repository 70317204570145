import React, { useState } from "react";
import SpaceAnalytics from "./spaceAnalytics/spaceAnalytics";
import RoomAnalytics from "./roomAnalytics/roomAnalytics";
import { useBuildingData } from "../common/useBuildingData";
import Loader from "../../common/loader/loader";
const FloorLayout = () => {
  const { loading, error } = useBuildingData();
  const [activeTab, setActiveTab] = useState("spaceanalytics");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      {loading && <Loader />}
      <div className="common-bg">
        <div className="container-fluid">
          {error && (
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="floor-layout-section">
            <div className="floor-layout-tabs">
              <button
                className={activeTab === "spaceanalytics" ? "active" : ""}
                onClick={() => handleTabChange("spaceanalytics")}
              >
                Space Analytics
              </button>
              <button
                className={activeTab === "roomanalytics" ? "active" : ""}
                onClick={() => handleTabChange("roomanalytics")}
              >
                Room Analytics
              </button>
            </div>
            <div
              className={`floor-layout-tab-content ${
                activeTab === "spaceanalytics" ? "active" : ""
              }`}
            >
              <SpaceAnalytics />
            </div>
            <div
              className={`floor-layout-tab-content ${
                activeTab === "roomanalytics" ? "active" : ""
              }`}
            >
              <RoomAnalytics />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloorLayout;
