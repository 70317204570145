import React, { useState, useEffect } from "react";
import DropIcon from "../../../../assets/images/select-icon.png";
import SearchIcon from "../../../../assets/images/search-icon.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AddIcon from "../../../../assets/images/add-icon.png";
import ModalCloseBtn from "../../../../assets/images/modal-close-btn.png";
import { useParams, NavLink } from "react-router-dom";
import Loader from "../../../common/loader/loader";
import axios from "axios";
import BuildingIconGreen from "../../../../assets/images/building-modal-icon.png";
import AddUser from "./addUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookedDetail from "./bookedDetail";
const FloorDetail = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [bookedModalShow, setBookedModalShow] = useState(false);
  const [bookingModalShow, setBookingModalShow] = useState(false);
  const [addUserModalShow, setAddUserModalShow] = useState(false);
  const [floors, setFloor] = useState([]);
  const [floorDetail, setFloorDetail] = useState([]);
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const [pathId, setPathId] = useState("");
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [displayQuery, setDisplayQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [spaceName, setSpaceName] = useState("");
  const [formData, setFormData] = useState({
    checkin_time: "",
    checkin_date: "",
    checkout_time: "",
    checkout_date: "",
    description: "",
  });

  const { id } = useParams();

  useEffect(() => {
    fetchUsers(searchQuery);
  }, [pageNumber, searchQuery]);
  const fetchUsers = async (query = "") => {
    setSpinner(true);
    try {
      const token = localStorage.token;
      const response = await axios.get(`${BASE_URL}admin/get_all_users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber,
          search: query,
        },
      });
      setUsers(response.data.data.users.data);
      setSpinner(false);
    } catch (error) {
      setError(error?.response?.data?.message);
      setSpinner(false);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setDisplayQuery(e.target.value);
    setPageNumber(1);
  };
  const handleUserAdd = (newUser) => {
    setSelectedUser(newUser.id);
    setDisplayQuery(newUser.username);
    setAddUserModalShow(false);
    setUsers([]);
  };
  const handleUserClick = (user) => {
    setSelectedUser(user.id);
    setDisplayQuery(user.username);
    setUsers([]);
  };
  useEffect(() => {
    fetchFloors();
  }, []);
  // floor api
  const fetchFloors = async () => {
    try {
      const token = localStorage.token;
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}admin/get_all_floors?building_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.floors.data;

      setFloor(data);
      console.log(data);
      if (data.length > 0) {
        setSelectedFloorId(data[0]?.id);
      }
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching floors:", error);
    }
  };
  useEffect(() => {
    if (selectedFloorId) {
      fetchFloorDetail();
    }
  }, [selectedFloorId]);

  //floor detail api
  const fetchFloorDetail = async () => {
    try {
      const token = localStorage.token;
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}admin/floor_details?id=${selectedFloorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.floor;
      setFloorDetail(data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching floors:", error);
    }
  };
  const handleFloorChange = (event) => {
    const selectedFloorId = event.target.value;
    setSelectedFloorId(selectedFloorId);
  };
  useEffect(() => {
    applyInitialSpaceColor();
  }, [floorDetail]);
  const applyInitialSpaceColor = () => {
    applySpaceColor(floorDetail);
  };
  const applySpaceColor = (floorData) => {
    if (floorData && floorData.spaces) {
      floorData.spaces.forEach((space) => {
        let hasSpaceColor = false;
        let hasWifi = false;
        let hasRouter = false;

        const pathId = `room${space.path_id}`;
        const pathElement = document.getElementById(pathId);

        if (pathElement && space.space_color) {
          pathElement.style.fill = space.space_color;
          hasSpaceColor = true;
        }

        const enableButtonId = `enable-btn${space.path_id}`;
        const enableButton = document.getElementById(enableButtonId);
        const enableButtonIcons = document.getElementsByClassName("svgBtnicon");

        if (enableButton) {
          enableButton.style.display = hasSpaceColor ? "block" : "none";
        }
        for (let i = 0; i < enableButtonIcons.length; i++) {
          enableButtonIcons[i].style.display = hasSpaceColor ? "block" : "none";
        }
        const textElement = document.getElementById(
          `space-name${space.path_id}`
        );
        if (textElement) {
          textElement.textContent = space.space_name;
          textElement.style.display = space.space_color ? "block" : "none";
        }
        if (space.devices) {
          if (space.devices.includes("Wifi")) {
            hasWifi = true;
          }
          if (space.devices.includes("Router")) {
            hasRouter = true;
          }
        }
        const wifiId = `wifi${space.path_id}`;
        const wifiElement = document.getElementById(wifiId);

        if (wifiElement) {
          wifiElement.style.display = hasWifi ? "block" : "none";
        }

        const routerId = `router${space.path_id}`;
        const routerElement = document.getElementById(routerId);

        if (routerElement) {
          routerElement.style.display = hasRouter ? "block" : "none";
        }
      });
    }
  };

  const handleClick = (event) => {
    const targetId = event.target.id;
    if (targetId.startsWith("enable-btn")) {
      const circleNumber = targetId.replace("enable-btn", "");
      setPathId(circleNumber);
      setBookingModalShow(true);
      const pathElement = document.getElementById(`path${circleNumber}`);
      // if (pathElement) {
      //   pathElement.classList.add("highlighted");
      // }

      const space = floorDetail.spaces.find(
        (space) => space.path_id === circleNumber
      );
      if (space) {
        setSpaceName(space.space_name);
      } else {
        setSpaceName("-");
      }
    }
  };
  useEffect(() => {
    const enableButtons = document.querySelectorAll('[id^="enable-btn"]');
    enableButtons.forEach((enableBtn) => {
      enableBtn.addEventListener("click", handleClick);
    });
    return () => {
      enableButtons.forEach((enableBtn) => {
        enableBtn.removeEventListener("click", handleClick);
      });
    };
  }, [floors]);
  const handleAddUser = () => {
    setAddUserModalShow(true);
    setBookingModalShow(false);
  };
  const hideAddUserModal = () => {
    setAddUserModalShow(false);
    setBookingModalShow(true);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  //booking api
  const handleAddBooking = async () => {
    try {
      setSpinner(true);
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}admin/booking`,
        {
          floor_id: selectedFloorId,
          user_id: selectedUser,
          building_id: id,
          path_id: pathId,
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Booking added successfully:");
      setSpinner(false);
      setBookingModalShow(false);
      setError(null);

      setSelectedUser([]);
    } catch (error) {
      setError(error?.response?.data?.message);
      setSpinner(false);
      console.error("Error adding booking:", error);
    }
  };
  return (
    <>
      {" "}
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="common-bg">
        <div className="container-fluid ">
          <div className="common-section floor-detail-section">
            <div className="custom-table-header">
              <NavLink to={`/building/floor/${id}`}>
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.74307 10.5428L12.8312 1.45473L11.7183 0.341797L1.51721 10.5428L11.3162 20.3418L12.4291 19.2289L3.74307 10.5428Z"
                    fill="#757575"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.131 9.89414L3.25288 9.89409L3.25288 11.468L20.131 11.4681L20.131 9.89414Z"
                    fill="#757575"
                  />
                </svg>
              </NavLink>
              <h1>
                {floorDetail?.building?.building_name || "-"}{" "}
                <span>{floorDetail?.floor_name || "-"}</span>
              </h1>
              <div className="select-field">
                <label>Select Floor</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={handleFloorChange}
                  value={selectedFloorId}
                >
                  {floors.map((floor) => (
                    <option key={floor.id} value={floor.id}>
                      {floor.floor_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className="__filter">
              <label>Filters</label>
              <div className="select-wrapper">
                <select class="">
                  <option selected>Buildings Sensor Map</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <img src={DropIcon} alt="" />
              </div>
            </div> */}
            <div className="booking-detail-wrapper mt-3">
              <NavLink to={"/building/floor/request-panel"}>
                View Booking Details
                <svg
                  width="9"
                  height="12"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.68066 13L7.68066 7L1.68066 1"
                    stroke="#303030"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </NavLink>
            </div>
            <div className="svg-container mt-5">
              <div className="svg-wrapper ">
                <div
                  onClick={handleClick}
                  dangerouslySetInnerHTML={{ __html: floorDetail?.svg_code }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="svg-color-wrapper">
                <div className="svg-color-header">
                  <ul>
                    <li>Group</li>
                    <li>Group Name</li>
                    {/* <li>Reports To</li> */}
                  </ul>
                </div>
                <div className="svg-color-body">
                  <ul>
                    <li>
                      <div className="group-color ceo"></div>
                      #FF98A0
                    </li>
                    <li>CEO & President</li>
                    {/* <li>1000</li> */}
                  </ul>
                  <ul>
                    <li>
                      <div
                        className="group-color marketing
"
                      ></div>
                      #376400
                    </li>
                    <li>Marketing</li>
                    {/* <li>1000</li> */}
                  </ul>
                  <ul>
                    <li>
                      <div
                        className="group-color client
"
                      ></div>
                      #008157
                    </li>
                    <li>Client Success</li>
                    {/* <li>1000</li> */}
                  </ul>
                  <ul>
                    <li>
                      <div
                        className="group-color operations
"
                      ></div>
                      #FFE700
                    </li>
                    <li>Operations</li>
                    {/* <li>1000</li> */}
                  </ul>
                  <ul>
                    <li>
                      <div
                        className="group-color sales
"
                      ></div>
                      #00D3FF
                    </li>
                    <li>Sales</li>
                    {/* <li>1000</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <Modal
        show={bookingModalShow}
        onHide={() => setBookingModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modal-header pb-0">
          <Button
            className="modal-close-button"
            onClick={() => setBookingModalShow(false)}
          >
            <img src={ModalCloseBtn} alt="image" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="moda-body-content">
            <div className="modal-content-header">
              <img src={BuildingIconGreen} alt="image" />
              <h1>Add New Booking</h1>
              <p>
                Please fill the Information to <strong>Add</strong> New{" "}
                <strong>Booking</strong>.
              </p>
            </div>

            <form>
              {error && (
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="row mt-3">
                <div className="col-lg-8">
                  <div className="mb-3 modal-form-field __icon-field">
                    <input
                      type="text"
                      placeholder="Search user"
                      value={displayQuery}
                      onChange={handleSearchChange}
                    />
                    <img src={SearchIcon} alt="" />
                    {searchQuery && users.length > 0 && (
                      <div className="search-data-list">
                        {users.map((user) => (
                          <div
                            key={user.id}
                            className="search-item"
                            onClick={() => handleUserClick(user)}
                          >
                            {user.username}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3 modal-form-field __btn-field">
                    <button onClick={handleAddUser}>
                      <img src={AddIcon} alt="" /> Add New User
                    </button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3 modal-form-field __multi-btn">
                    <button className="active">Manual</button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3 modal-form-field __multi-btn">
                    <button>Auto ( Sensors)</button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 modal-form-field">
                    <label>Building Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Building Name"
                      readOnly
                      value={floorDetail?.building?.building_name || "-"}
                    />
                  </div>
                  <div className="mb-3 modal-form-field">
                    <label>Space Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Building Name"
                      readOnly
                      value={spaceName || "-"}
                    />
                  </div>
                  <div className="mb-3 modal-form-field">
                    <label>Floor Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Building Name"
                      readOnly
                      value={floorDetail?.floor_name || "-"}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3 modal-form-field">
                    <label>Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Start Date"
                      name="checkin_date"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3 modal-form-field">
                    <label>End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter End Date"
                      name="checkout_date"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3 modal-form-field">
                    <label>Start Time</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Enter Start Time"
                      name="checkin_time"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3 modal-form-field">
                    <label>End Time</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Enter End Time"
                      name="checkout_time"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mb-3 modal-form-field">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    placeholder="Description Here"
                    name="description"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>

              <div className="mb-3 modal-form-field-btn">
                <button
                  type="button"
                  onClick={() => setBookingModalShow(false)}
                >
                  Cancel
                </button>
                <button type="button" onClick={handleAddBooking}>
                  Add Booking
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* user-modal */}
      <AddUser
        show={addUserModalShow}
        onHide={hideAddUserModal}
        setSpinner={setSpinner}
        toast={toast}
        handleUserAdd={handleUserAdd}
      />
      {/* booked-floor-detail */}
      <BookedDetail
        show={bookedModalShow}
        onHide={() => setBookedModalShow(false)}
        setSpinner={setSpinner}
      />
    </>
  );
};

export default FloorDetail;
