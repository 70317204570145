import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialState = {
  loading: false,
  spaceGraphData: [],
  error: "",
};
export const fetchSpaceGraphData = createAsyncThunk(
  "spaceGraph/fetchSpaceGraphData",
  async () => {
    const token = localStorage.token;
    const response = await axios.get(`${BASE_URL}user/dashboard_graph`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data?.data;
  }
);
const spaceGraphSlice = createSlice({
  name: "spaceGraph",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSpaceGraphData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSpaceGraphData.fulfilled, (state, action) => {
      state.loading = false;
      state.spaceGraphData = action.payload;
      state.error = "";
    });
    builder.addCase(fetchSpaceGraphData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export default spaceGraphSlice.reducer;
