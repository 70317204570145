import React, { useState } from "react";
import Logo from "../../../assets/images/logo.png";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { axiosPostCall } from "../../../api/auth/auth";
import { ToastContainer, toast } from "react-toastify";

const OPT = () => {
  const location = useLocation();
  const [optCode, setOptCode] = useState({ pin_code: "" });
  const [error, setError] = useState(null);
  let navigation = useNavigate();
  const userchange = (e) => {
    const { name, value } = e.target;
    setOptCode({ ...optCode, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const endpoint = "verify-otp";
      await axiosPostCall(endpoint, {
        ...optCode,
        email: location.state.email,
      });
      toast.success("Otp Verified!", {
        onClose: () =>
          navigation("/new-password", {
            state: {
              email: location.state.email,
              pin_code: optCode.pin_code,
            },
          }),
      });
      setError(null);
    } catch (error) {
      const errorContent = error.response.data.message;
      console.log(error);
      setError(errorContent);
    }
  };
  const handleResendCode = async () => {
    try {
      const endpoint = "verify-email";
      await axiosPostCall(endpoint, { email: location.state.email });
      toast.success("Code Resent Successfully!");
    } catch (error) {
      const errorContent = error.response.data.message;
      console.log(error);
      setError(errorContent);
    }
  };
  return (
    <div className="container-fluid auth-section">
      <div className="auth-section-banner">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="auth-section-banner-content">
          <h1>
            Enter an OPT code Sent on your email and change your Password{" "}
          </h1>
          <p>
            Enter your Email and Password for login to Space Management Enter
            your Email and Password for login to Space ManagementEnter your
            Email and Password for login to Space Management
          </p>
        </div>
      </div>
      <div className="auth-section-form">
        <div className="auth-section-form-content">
          <div className="auth-logo">
            <img src={Logo} alt="logo" />
            <p>A Code to reset your password will be sent to your email</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Enter OPT Code</h1>
              </div>
            </div>
            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <div className="mb-4 form-field ">
              <input
                type="text"
                className="form-control"
                placeholder="Enter OTP"
                name="pin_code"
                onChange={userchange}
                value={optCode.pin_code}
              />
            </div>

            <div className="form-field mb-4">
              <button type="submit">Verify OTP</button>
            </div>
            <div className="resend-opt mb-4">
              <p>
                Didn’t Receive the Verification OTP{" "}
                <span onClick={handleResendCode}>Resend?</span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OPT;
