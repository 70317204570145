import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/images/back-arrow.png";
import ProfileImage from "../../../assets/images/profile-img.png";
import EditIcon from "../../../assets/images/edit-icon.png";
const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  useEffect(() => {
    const userAuth = localStorage.getItem("user");
    if (userAuth) {
      const parsedUser = JSON.parse(userAuth);
      setUser(parsedUser);
    }
  }, []);
  const handleBackClick = () => {
    if (user.type === "user") {
      navigate("/user-dashboard");
    } else {
      navigate("/dashboard");
    }
  };
  return (
    <div className="common-bg">
      <div className="container-fluid profile-section">
        <div className="profile-header">
          <div className="profile-header-link">
            <button onClick={handleBackClick}>
              <img src={BackIcon} alt="" /> Back to Dashboard
            </button>
          </div>
          <div className="profile-header-img">
            <a href={user.image} target="_blank" rel="noopener noreferrer">
              <img src={user.image} alt="" />
            </a>
            <h1>
              {user.username} <span>{user.type}</span>
            </h1>
          </div>
        </div>
        <div className="profile-body common-section">
          <div className="profile-body-header">
            <NavLink to="/profile/edit-profile">
              <img src={EditIcon} alt="" /> Edit Information
            </NavLink>
          </div>
          <div className="profile-body-content">
            <div className="profile-body-heading">
              <h1>Personal Information</h1>
            </div>
            <div className="profile-body-table mt-3">
              <div className="profile-table-row">
                <div className="row-content">
                  <h1>Name</h1>
                  <p>{user.username}</p>
                </div>
                <div className="row-content">
                  <h1>Phone</h1>
                  <p>{user.phone_number}</p>
                </div>
              </div>

              {/* <div className="profile-table-row">
                <div className="row-content status">
                  <h1>Status</h1>
                  <p>
                    <div
                      className={`status-box ${
                        user.status === 1 ? "active" : ""
                      }`}
                    ></div>
                    {user.status === 1 ? "Active" : "Inactive"}
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
