import React from "react";
import BuildingIcon from "../../../assets/images/building-stats-icon.png";
import RequestIcon from "../../../assets/images/requests-stats-icon.png";
import UserIcon from "../../../assets/images/user-stats-icon.png";
import OwnerIcon from "../../../assets/images/owner-stats-icon.png";
const Card = ({ cards }) => {
  return (
    <div className="row">
      <div className="col-xl-3 mb-3">
        <div className="value-card">
          <div className="value-card-header">
            <h1>
              <img src={UserIcon} alt="img" />
              Users
            </h1>
          </div>
          <div className="value-card-body">
            <h2>
              No of Users<span>Total</span>
            </h2>
            <h1>{cards["total_users"]}</h1>
          </div>
        </div>
      </div>
      <div className="col-xl-3 mb-3">
        <div className="value-card">
          <div className="value-card-header">
            <h1>
              <img src={OwnerIcon} alt="img" />
              Owners
            </h1>
          </div>
          <div className="value-card-body">
            <h2>
              No of Owners<span>Total</span>
            </h2>
            <h1>{cards["total_owners"]}</h1>
          </div>
        </div>
      </div>
      <div className="col-xl-3 mb-3">
        <div className="value-card">
          <div className="value-card-header">
            <h1>
              <img src={BuildingIcon} alt="img" />
              Buildings
            </h1>
          </div>
          <div className="value-card-body">
            <h2>
              No of Buildings<span>Total</span>
            </h2>
            <h1>{cards["total_buildings"]}</h1>
          </div>
        </div>
      </div>
      <div className="col-xl-3 mb-3">
        <div className="value-card">
          <div className="value-card-header">
            <h1>
              <img src={RequestIcon} alt="img" />
              Users Requests
            </h1>
          </div>
          <div className="value-card-body">
            <h2>
              No of Users Requests<span>Total</span>
            </h2>
            <h1>{cards["total_request"]}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
