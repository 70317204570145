import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = {
  loading: false,
  buildingNames: [],
  error: null,
  selectedBuilding: null,
};

export const fetchBuildingNames = createAsyncThunk(
  "buildingName/fetchBuildingNames",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.token;
      const response = await axios.get(`${BASE_URL}admin/buildings_names`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data?.data?.buildings;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

const buildingNameSlice = createSlice({
  name: "buildingName",
  initialState,
  reducers: {
    setSelectedBuilding: (state, action) => {
      state.selectedBuilding = action.payload;
    },
    resetBuildingState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuildingNames.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBuildingNames.fulfilled, (state, action) => {
        state.loading = false;
        state.buildingNames = action.payload;
      })
      .addCase(fetchBuildingNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { setSelectedBuilding, resetBuildingState } =
  buildingNameSlice.actions;
export default buildingNameSlice.reducer;
