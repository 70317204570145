import React, { useEffect } from "react";
import Banner from "./banner";
import BuildingSpace from "./buildingSpace";
import FeaturesBuildings from "./featuresBuildings";
import BuildingList from "./buildingList";
import AnalyticsChart from "./analyticsChart/analyticsChart";
import Consultation from "./consultation";
import Loader from "../../common/loader/loader";
import { useSelector, useDispatch } from "react-redux";
import { fetchBuildings } from "../../../slices/buildingsSlice";
const HomeIndex = () => {
  const dispatch = useDispatch();
  const { loading, buildings } = useSelector((state) => state.buildings);
  useEffect(() => {
    dispatch(fetchBuildings({}));
  }, [dispatch]);
  return (
    <>
      {loading && <Loader />}
      <Banner />
      <BuildingSpace />
      <FeaturesBuildings buildings={buildings} />
      <BuildingList buildings={buildings} />
      <AnalyticsChart />
      <Consultation />
    </>
  );
};

export default HomeIndex;
