import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const UtilizationSemiDonutChart = () => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleStartDateChange = (date) => {
    const startOfWeek = moment(date).toDate();
    const endOfWeek = moment(date).add(6, "days").toDate();
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };
  const toggleDatePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };
  const datePickerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const rooms = [
    { name: "Room A", percentage: 20 },
    { name: "Room B", percentage: 10 },
    { name: "Room C", percentage: 90 },
    { name: "Room D", percentage: 70 },
  ];
  return (
    <div className="utilization-semi-donut-section mt-3">
      <div className="utilization-bar-chart-header">
        <div className="graph-heading pb-0">
          <h1 className="m-0">
            <span>Meeting Room Utilization</span>
            Meeting room color indicator
          </h1>
        </div>

        <div className="range-picker-wrapper">
          <button className="date-toggle" onClick={toggleDatePicker}>
            Date Range
            <KeyboardArrowDownIcon />
          </button>
          {isPickerOpen && (
            <div className="range-picker" ref={datePickerRef}>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsStart
                inline
              />
            </div>
          )}
        </div>
      </div>
      <div className="semi-donut-chart-body mt-3">
        <div className="semi-donut-chart-header">
          <h1>Ground Floor</h1>
          <div className="chart-status">
            <h1>
              <div className="status-box free"></div>Free
            </h1>
            <h1>
              <div className="status-box occupied"></div>Occupied
            </h1>
          </div>
        </div>
        <div className="semi-donut-chart-row">
          {rooms.map((room, index) => (
            <div key={index} className="semi-donut-chart-wrapper mb-3">
              <div className="semi-donut-chart">
                <div className="multi-graph margin">
                  <div
                    className="graph"
                    style={{ "--percentage": room.percentage }}
                  ></div>
                </div>
                <h1>{room.name}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UtilizationSemiDonutChart;
