import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalCloseBtn from "../../../../assets/images/modal-close-btn.png";
import BuildingIconGreen from "../../../../assets/images/building-modal-icon.png";
import AddFile from "./fileUpload/dropZone";

const UpdateFloor = ({
  show,
  onHide,
  fetchFloors,
  setSpinner,
  toast,
  floor,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [oldFloorImages, setOldFloorImages] = useState([]);
  const [error, setError] = useState(null);
  const [imageData, setImageData] = useState([]);
  const [floorData, setFloorData] = useState({
    floor_name: "",
    floor_number: "",
    svg_code: "",
  });

  useEffect(() => {
    if (!show) {
      setError(null);
      setFloorData({
        floor_name: "",
        floor_number: "",
        svg_code: "",
      });
      setOldFloorImages([]);
      setImageData([]);
    }
  }, [show]);
  useEffect(() => {
    if (floor) {
      setFloorData({
        floor_name: floor.floor_name,
        floor_number: floor.floor_number,
        svg_code: floor.svg_code,
      });
      setOldFloorImages(floor.files);
    }
  }, [floor]);

  const handleShowImageData = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === "image/svg+xml") {
        const reader = new FileReader();
        reader.onload = (event) => {
          console.log(event.target.result,"code");
          setFloorData((prevState) => ({
            ...prevState,
            svg_code: event.target.result,
          }));
         
        };
        reader.readAsText(file);
      } else {
        setError("Please upload a valid SVG file.");
      }
      setImageData(files);
      setOldFloorImages([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFloorData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const uploadImages = async () => {
    try {
      const formData = new FormData();
      imageData.forEach((file) => {
        formData.append("files[]", file);
      });
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}user/upload_files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      setError("Error uploading images:", error);
      throw error;
    }
  };

  const handleUpdateFloor = async () => {
    setSpinner(true);
    try {
      let finalImages = oldFloorImages;
      if (imageData.length > 0) {
        const imageUrls = await uploadImages();
        finalImages = [...imageUrls.map((image) => image)];
        setOldFloorImages([]);
      }
     
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}admin/floor?id=${floor.id}`,
        {
          ...floorData,
          files_data: finalImages,
          building_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onHide();
      toast.success("Floor updated Successfully");
      fetchFloors();
      setError(null);
      setSpinner(false);
      setImageData([]);
    } catch (error) {
      setError(error.response.data.message);
      setSpinner(false);
    }
  };

  const handleModalHide = () => {
    onHide();
    setImageData([]);
  };
  const handleRemoveImage = (imagePath) => {
    const updatedOldFloorImages = oldFloorImages.filter(
      (image) => image !== imagePath
    );
    setOldFloorImages(updatedOldFloorImages);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleModalHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modal-header pb-0">
          <Button className="modal-close-button" onClick={handleModalHide}>
            <img src={ModalCloseBtn} alt="image" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="moda-body-content">
            <div className="modal-content-header">
              <img src={BuildingIconGreen} alt="image" />
              <h1>Update Floor</h1>
              <p>Please update the floor information below.</p>
            </div>
            <form>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="mb-3 modal-form-field">
                <AddFile onShowImageData={handleShowImageData} />
              </div>
              <div className="old-image-container">
                {oldFloorImages.map((image, index) => (
                  <div key={index} className="image-wrapper">
                    <img src={image} alt={`Old Image ${index}`} />
                    <button
                      type="button"
                      className="remove-button"
                      onClick={() => handleRemoveImage(image)}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
              <div className="mb-3 modal-form-field">
                <label>Floor Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Floor Name"
                  name="floor_name"
                  value={floorData.floor_name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 modal-form-field">
                <label>Floor Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Location Here"
                  name="floor_number"
                  value={floorData.floor_number}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="mb-3 modal-form-field">
                <label>Floor Svg Code</label>

                <textarea
                  onChange={handleChange}
                  className="form-control"
                  name="svg_code"
                  value={floorData.svg_code}
                  placeholder="Paste SVG code here..."
                />
              </div> */}
              <div className="mb-3 modal-form-field-btn">
                <button onClick={onHide}>Cancel</button>
                <button type="button" onClick={handleUpdateFloor}>
                  Update Floor
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateFloor;
