import React from "react";
import BuildingBarChart from "./buildingBarChart";
import { NavLink } from "react-router-dom";

const AnalyticsChart = () => {
  return (
    <div className="container analyticschart-section">
      <div className="row">
        <div className="col-xl-6 mb-3">
          <BuildingBarChart />
        </div>
        <div className="col-xl-6 mb-3 analytic-space-card-wrapper">
          <div className="analytic-space-card">
            <h1>Buildings space analytics</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
            {/* <NavLink>Full Screen Chart</NavLink> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsChart;
