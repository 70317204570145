import React from "react";
const Banner = () => {
  return (
    <div className="container-fluid home-section landing-building-section">
      <div className="container home-container">
        <h1>All Buildings </h1>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
      </div>
    </div>
  );
};

export default Banner;
