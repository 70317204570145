import { useState } from "react";
import Filters from "../roomUtilization/filters";
import UtilizationChart from "../roomUtilization/utilizationChart";
import { useBuildingData } from "../common/useBuildingData";
import Loader from "../../common/loader/loader";
import UtilizationSemiDonutChart from "../common/utilizationSemiDonutChart/utilizationSemiDonutChart";
import GridChart from "./gridChart";
import OccupancyBarChart from "../common/occupancyBarChart/occupancyBarChart";
import OccupancyLineChart from "../common/occupancyLineChart/occupancyLineChart";
import ReactApexChart from "react-apexcharts";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WingIcon from "../../../assets/images/wing-icon.png";
import BarChart from "./barChart";
const SpacePerformance = () => {
  const {
    loading,
    error,
    buildingNames,
    floorNames,
    roomNames,
    roomUtilizationData,
    selectedRoomName,
    handleBuildingChange,
    handleFloorChange,
    handleRoomChange,
  } = useBuildingData();

  const { utilization } = roomUtilizationData || [];
  const utilizationValue = utilization || 0;
  const utilizationStyle = {
    "--percentage": `${utilizationValue}`,
  };
  const [view, setView] = useState("weekly");

  const handleViewChange = (event) => {
    setView(event.target.value === "1" ? "weekly" : "monthly");
  };
  const [series] = useState([75, 25]);
  const [options] = useState({
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    colors: ["#24CCA7", "#F1F1F1"],
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "16px",
              fontWeight: "bold",
              color: "#000",
              formatter: (value) => "Total",
            },
            value: {
              show: true,
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000",
              formatter: (value) => value,
            },
          },
        },
      },
    },
    labels: ["Unutilization", "Utilization"],
  });
  const [activeTab, setActiveTab] = useState("semidonut");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      {loading && <Loader />}{" "}
      <div className="common-bg">
        <div className="container-fluid">
          {error && (
            <div class="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="common-section building-corporate-section">
            <div className="custom-table-header">
              <h1>Building Carporate</h1>
              <div className="widget-wrapper">
                <div className="select-wrappper widget-col">
                  <select className="form-control">
                    <option selected disabled>
                      All Widgets
                    </option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                  <svg
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#24CCA7"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <Filters
              handleBuildingChange={handleBuildingChange}
              buildingNames={buildingNames}
              handleFloorChange={handleFloorChange}
              floorNames={floorNames}
              handleRoomChange={handleRoomChange}
              roomNames={roomNames}
            />
          </div>
          <div className="building-corporate-graph mt-3">
            <div className="corporate-graph-col">
              {" "}
              <UtilizationChart
                utilizationStyle={utilizationStyle}
                utilizationValue={utilizationValue}
                customClass="d-none"
                selectedRoomName={selectedRoomName}
              />
            </div>
            <div className="corporate-graph-col">
              <div className="graph-heading">
                <h1>
                  <span>Statistics</span>
                  Peek Occupancy Percentage
                </h1>
              </div>
              <div className="chart-status justify-content-end">
                <h1>
                  <div className="status-box full-occupied"></div>Fully Occupied
                </h1>
                <h1>
                  <div className="status-box medium-occupied"></div>Mid Level
                  Occupied
                </h1>
                <h1>
                  <div className="status-box mid-free"></div>Mid Free
                </h1>
                <h1>
                  <div className="status-box full-free"></div>Full Free
                </h1>
              </div>
              <div className="corporate-graph-filter">
                <h1>
                  All Days<span>Working Days</span>
                </h1>
                <div className="select-wrappper widget-col">
                  <select className="form-control" onChange={handleViewChange}>
                    <option value="1">Weekly</option>
                    <option value="2">Monthly</option>
                  </select>
                  <svg
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#24CCA7"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="corporate-graph-card-wrapper">
                <GridChart view={view} />
              </div>
            </div>
          </div>

          <div className="space-performance-tabs-section mt-3">
            <div className="space-performance-tabs">
              <button
                className={activeTab === "semidonut" ? "active" : ""}
                onClick={() => handleTabChange("semidonut")}
              >
                Semi Donut
              </button>
              <button
                className={activeTab === "bar" ? "active" : ""}
                onClick={() => handleTabChange("bar")}
              >
                Bar
              </button>
            </div>
            <div
              className={`building-tab ${
                activeTab === "semidonut" ? "active" : ""
              }`}
            >
              <UtilizationSemiDonutChart />
            </div>
            <div
              className={`building-tab ${activeTab === "bar" ? "active" : ""}`}
            >
              <BarChart />
            </div>
          </div>
          <OccupancyBarChart />
          <OccupancyLineChart />
          <div className="wing-chart-wrapper mt-3">
            <div className="wing-chart-header">
              <h1>Ground Floor</h1>
            </div>
            <div className="wing-chart-body mt-3">
              <div className="donut-chart-box">
                <div className="graph-heading pb-0">
                  <h1 className="m-0">
                    <span>Statistics</span>
                    Ground Floor Current Utilization
                  </h1>
                </div>
                <div className="donut-chart mt-2">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="donut"
                    width={300}
                  />
                </div>
                <p>
                  <AccessTimeIcon className="clock-icon" />
                  Updated on 03-August-2023 06:00 AM
                </p>
              </div>
              <div className="wing-card-box">
                <div className="wing-card">
                  <img src={WingIcon} alt="" />
                  <h1>
                    Wing 1 - 40.00%<span>Current Most Utilized Zone </span>
                  </h1>
                </div>
              </div>
              <div className="yearly-utilization-chart">
                <div className="graph-heading pb-0">
                  <h1 className="m-0">
                    <span>Statistics</span>
                    Yearly Utilization
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpacePerformance;
