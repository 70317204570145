import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import ProfileImg from "../../../assets/images/profile-img.png";
import BuildingImg from "../../../assets/images/building.jpg";
import DotIcon from "../../../assets/images/dots-horizontal-icon.png";

const BuildingList = ({ buildings }) => {
  return (
    <div className="common-section">
      <div className="custom-table-header">
        <h1>List of all Buildings</h1>
        <NavLink to="/building">See All</NavLink>
      </div>
      <div className="table-body">
        <div className="custom-table">
          <div className="table-header-row">
            <div className="table-cell">Name</div>
            <div className="table-cell">Location</div>
            <div className="table-cell">Building Owner</div>
          </div>
          {buildings.map((building) => (
            <div className="table-row" key={building.id}>
              <div className="table-cell" data-heading="Name:">
                <div className="table-cell-inner">
                  <div className="d-flex align-items-center column-gap-1">
                    <img
                      className="building-img"
                      src={building.files[0]}
                      alt="img"
                    />
                    {building.building_name ?? "-"}
                  </div>
                </div>
              </div>
              <div className="table-cell" data-heading="Location:">
                <div className="table-cell-inner">
                  {building.location ?? "-"}
                </div>
              </div>
              <div className="table-cell" data-heading="Building Owner:">
                <div className="table-cell-inner">
                  <div className="d-flex align-items-center column-gap-2">
                    <img
                      className="profile-img"
                      src={building.owner.image}
                      alt="img"
                    />
                    <span className="text-elipsies">
                      {building.owner.username
                        .split(" ")
                        .slice(0, 2)
                        .join(" ") ?? "-"}
                      <label className="text-elipsies">
                        {building.owner.email ?? "-"}
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuildingList;
