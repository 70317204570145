import React, { useState } from "react";
import Logo from "../../../assets/images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { axiosPostCall } from "../../../api/auth/auth";
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = () => {
  const [opt, setOpt] = useState({ email: "" });
  const [error, setError] = useState(null);
  let navigation = useNavigate();
  const userchange = (e) => {
    const { name, value } = e.target;
    setOpt({ ...opt, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const endpoint = "verify-email";
      const response = await axiosPostCall(endpoint, opt);
      console.log(response, "test");
      toast.success("Code Send successfully!", {
        onClose: () => navigation("/opt", { state: { email: opt.email } }),
      });
      setError(null);
    } catch (error) {
      const errorContent = error.response.data.message;
      console.log(error);
      setError(errorContent);
    }
  };
  return (
    <div className="container-fluid auth-section">
      <div className="auth-section-banner">
        <ToastContainer position="top-right" autoClose={700} theme="colored" />
        <div className="auth-section-banner-content">
          <h1>Enter your Email to Reset your Space Management Password</h1>
          <p>
            Enter your Email and Password for login to Space Management Enter
            your Email and Password for login to Space ManagementEnter your
            Email and Password for login to Space Management
          </p>
        </div>
      </div>
      <div className="auth-section-form">
        <div className="auth-section-form-content">
          <div className="auth-logo">
            <img src={Logo} alt="logo" />
            <p>A Code to reset your password will be sent to your email</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="form-heading">
                <h1>Forgot Password</h1>
              </div>
            </div>
            {error && (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="mb-4 form-field ">
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
                name="email"
                onChange={userchange}
                value={opt.email}
              />
            </div>

            <div className="form-field mb-4">
              <button type="submit">Send Code</button>
            </div>
            {/* <div className="form-field">
              <NavLink to={"/"}>
                <button className="transparent-btn" type="submit">
                  Log In
                </button>
              </NavLink>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
