import React from "react";
import SearchIcon from "../../../assets/images/landingImages/search-icon.png";
const Banner = () => {
  return (
    <div className="container-fluid home-section">
      <div className="container home-container">
        <h1>Dummy Text Dummy Text Sit Dollor Lorem Ipsum</h1>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </p>
        {/* <div className="banner-search-container">
          <div className="search-bar">
            <input type="text" />
            <img src={SearchIcon} alt="img" />
          </div>
          <button>Search</button>
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
