import React, { useEffect, useState } from "react";
import Card from "./cards";
import axios from "axios";
import Demographic from "./demographic";
import Loader from "../../common/loader/loader";
import BuildingList from "./buildingList";

const DashboardIndex = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [cards, setCards] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [users, setUsers] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const fetchDashboard = async () => {
    try {
      setSpinner(true);
      const token = localStorage.token;
      const response = await axios.get(`${BASE_URL}admin/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });

      let dashboardData = response?.data?.data;
      setCards(dashboardData.dashboard_stats);
      setUsers(dashboardData.users.data);
      setBuildings(dashboardData.buildings.data);
      setGraphData(dashboardData.graph_data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching cards:", error);
    }
  };
  useEffect(() => {
    fetchDashboard();
  }, []);
  return (
    <>
      {spinner && <Loader />}
      <div className="common-bg">
        <div className="container-fluid">
          <Card cards={cards} />
          <Demographic users={users} graphData={graphData} />
          <BuildingList buildings={buildings} />
        </div>
      </div>
    </>
  );
};

export default DashboardIndex;
