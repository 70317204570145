import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocationIcon from "../../../assets/images/location-icon-white.png";
import FloorIcon from "../../../assets/images/floor-icon-white.png";
const Banner = ({ buildings }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };
  return (
    <>
      <div className="container-fluid landing-slider-section">
        <div className="slider-container">
          <div className="slider-header">
            <h1>{buildings.building_name || "-"}</h1>
            <p>
              Buildings {">"} Buildings Details {">"}
            </p>
            <h2>
              <img src={LocationIcon} alt="" />
              {buildings.location}
            </h2>
          </div>
          <div className="slider-body mt-3">
            <div className="__slider-body-header">
              <div className="__profile">
                <div className="__profile-img-wrapper">
                  <img src={buildings?.owner?.image || "-"} alt="" />
                </div>

                <h1>
                  {buildings?.owner?.username || "-"}
                  <span>
                    {buildings?.owner?.total_buildings || "-"} Buildings
                  </span>
                </h1>
              </div>
              <div className="__floor">
                <p>
                  <img src={FloorIcon} alt="" /> {buildings.total_floors || "-"}{" "}
                  Floors
                </p>
                <p>
                  600 Person <span>Capacity</span>
                </p>
              </div>
            </div>
            <div className="__slider-body-description">
              <p>{buildings.descriptions || "-"}</p>
            </div>
          </div>
        </div>
        <Slider {...settings} className="slider-wrapper">
          <div className="slide-content slide-content-one"></div>
          <div className="slide-content slide-content-two"></div>
          <div className="slide-content slide-content-three"></div>
        </Slider>
      </div>
    </>
  );
};

export default Banner;
