import React from "react";

const UtilizationChart = ({
  utilizationStyle,
  utilizationValue,
  underutiliazationStyle,
  underutiliazationValue,
  customClass,
  selectedRoomName,
}) => {
  return (
    <div className="graph-wrapper">
      <div className="graph-col">
        <div className="graph-heading">
          <h1>
            <span>Statistics</span>
            {selectedRoomName} Utilization
          </h1>
        </div>
        <div className="semi-donut-wrapper my-4">
          <div className="semi-dounut" style={utilizationStyle}>
            <h1>
              <span>Total Count</span>
              {utilizationValue}%
            </h1>
          </div>
          <div className="donut-label">
            <span>0%</span>
            <span>100%</span>
          </div>
        </div>
      </div>
      <div className={`graph-col ${customClass}`}>
        <div className="graph-heading">
          <h1>
            <span>Statistics</span>
            {selectedRoomName} Utilization
          </h1>
        </div>
        <div className="semi-donut-wrapper my-4">
          <div className="semi-dounut" style={underutiliazationStyle}>
            <h1>
              <span>Total Count</span>
              {underutiliazationValue}%
            </h1>
          </div>
          <div className="donut-label">
            <span>0%</span>
            <span>100%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtilizationChart;
