import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  userRegistration,
  resetUserRegister,
} from "../../../slices/userRegisterSlice";
import Logo from "../../../assets/images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PasswordIcon from "../../../assets/images/pasword-icon.png";
import Loader from "../../../components/common/loader/loader";

const Signup = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone_number: "",
  });

  const [passwordError, setPasswordError] = useState("");

  const dispatch = useDispatch();
  const {
    loading,
    user,
    error: signupError,
    userRegister,
  } = useSelector((state) => state.signup);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    setPasswordError("");
    const { confirmPassword, ...submitData } = formData;
    dispatch(userRegistration(submitData));
  };
  useEffect(() => {
    if (userRegister) {
      toast.success(user.message || "Registration successful!", {
        onClose: () => {
          dispatch(resetUserRegister());
          navigate("/user-login");
        },
      });
    }
  }, [userRegister, dispatch, navigate]);
  return (
    <>
      {loading && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="container-fluid auth-section">
        <div className="auth-section-banner">
          <ToastContainer
            position="top-right"
            autoClose={700}
            theme="colored"
          />
          <div className="auth-section-banner-content">
            <h1>Sign Up for Space Management</h1>
            <p>
              Enter your Email and Password to create an account for Space
              Management. Provide your details to join and manage your space
              efficiently.
            </p>
          </div>
        </div>
        <div className="auth-section-form">
          <div className="auth-section-form-content">
            <div className="auth-logo">
              <img src={Logo} alt="logo" />
              <p>
                Enter your Email and Password to sign up for Space Management
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="form-heading">
                  <h1>Signup</h1>
                </div>
              </div>
              {signupError && (
                <div className="alert alert-danger" role="alert">
                  {signupError}
                </div>
              )}
              <div className="mb-4 form-field ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name Here"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4 form-field ">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email Here"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4 form-field ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone Here"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4 form-field ">
                <div className="form-field-icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Password Here"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <img className="icon-left" src={PasswordIcon} alt="logo" />
                  {!showPassword ? (
                    <VisibilityOutlinedIcon
                      className="eye-icon"
                      onClick={handlePassword}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      className="eye-icon"
                      onClick={handlePassword}
                    />
                  )}
                </div>
              </div>

              <div className="mb-4 form-field ">
                <div className="form-field-icon">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm Your Password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  <img className="icon-left" src={PasswordIcon} alt="logo" />
                  {!showConfirmPassword ? (
                    <VisibilityOutlinedIcon
                      className="eye-icon"
                      onClick={handleConfirmPasswordToggle}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      className="eye-icon"
                      onClick={handleConfirmPasswordToggle}
                    />
                  )}
                </div>
              </div>
              {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
              <div className="form-field mb-4">
                <button type="submit" disabled={loading}>
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
