import React, { useState, useEffect } from "react";
import ArrowIcon from "../../../assets/images/arrow-icon-back.png";
import UploadIcon from "../../../assets/images/upload-img-icon.png";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Loader from "../../common/loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
const EditProfile = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  let navigation = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [image, setImage] = useState(null);
  const [user, setUser] = useState({
    username: "",
    phone_number: "",
    status: "1",
  });
  console.log(image, "image");
  useEffect(() => {
    const userAuth = localStorage.getItem("user");
    if (userAuth) {
      const parsedUser = JSON.parse(userAuth);
      setUser(parsedUser);
      if (parsedUser.image) {
        setImage(parsedUser.image);
      }
    }
  }, []);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("files[]", file);

      try {
        setSpinner(true);
        const token = localStorage.token;
        const response = await axios.post(
          `${BASE_URL}user/upload_files`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const imageUrl = response.data.data[0];
        setSpinner(false);
        setImage(imageUrl);
      } catch (error) {
        console.error("There was an error uploading the file!", error);
        setSpinner(false);
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setSpinner(true);
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}user/update_profile`,
        {
          ...user,
          image: image,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data.user, "updated data");
      const newUser = JSON.stringify(response.data.data.user);
      localStorage.setItem("user", newUser);
      toast.success("Profile Updated successfully!", {
        onClose: () => navigation("/profile"),
      });
      setSpinner(false);
    } catch (error) {
      console.error("There was an error updating the profile!", error);
      setSpinner(false);
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  return (
    <>
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="common-bg">
        <div className="container-fluid ">
          <div className="common-section edit-profile-section">
            <div className="edit-profile-header">
              <NavLink to="/profile">
                <img src={ArrowIcon} alt="" /> Profile
              </NavLink>
            </div>
            <div className="edit-profile-body">
              <form onSubmit={handleSubmit}>
                <div className="edit-profile-file">
                  <label>Change Profile Picture</label>
                  <div className="file-wrapper mt-3">
                    <div className="upload-container">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        id="imageUpload"
                      />
                      <label htmlFor="imageUpload" className="custom-button">
                        <img src={UploadIcon} alt="" />
                      </label>
                      {image && (
                        <div className="imagePreview">
                          <img src={image} alt="Uploaded Preview" />
                        </div>
                      )}
                    </div>
                    <div className="profile-name">
                      <h1>
                        {user.username}
                        <span>{user.type}</span>
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6 edit-form-field mb-3">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name here..."
                      name="username"
                      value={user.username}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6 edit-form-field mb-3">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="phone here..."
                      name="phone_number"
                      value={user.phone_number}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div className="col-12 mb-3 change-status">
                    <p>Change Status</p>
                    <div class="radio-group">
                      <input
                        type="radio"
                        id="active"
                        name="status"
                        value="1"
                        checked={user.status === "1"}
                        onChange={handleInputChange}
                      />
                      <label for="active" class="custom-radio">
                        Active
                      </label>

                      <input
                        type="radio"
                        id="inactive"
                        name="status"
                        value="0"
                        checked={user.status === "0"}
                        onChange={handleInputChange}
                      />
                      <label for="inactive" class="custom-radio">
                        Inactive
                      </label>
                    </div>
                  </div> */}
                  <div className="col-12 edit-form-field text-end">
                    <button type="submit">Save Changes</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
