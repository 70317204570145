import React, { useState, useEffect } from "react";
import PickerIcon from "../../../../assets/images/color-wheel.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ModalCloseBtn from "../../../../assets/images/modal-close-btn.png";
import { useParams, NavLink } from "react-router-dom";
import Loader from "../../../common/loader/loader";
import axios from "axios";
import BuildingIconGreen from "../../../../assets/images/building-modal-icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SketchPicker } from "react-color";
const FloorSpace = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [spaceModalShow, setSpaceModalShow] = useState(false);
  const [floors, setFloor] = useState([]);
  const [pathID, setPathID] = useState("");
  const [error, setError] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [spaceData, setSpaceData] = useState({
    space_name: "",
    floor_id: "",
    path_id: "",
    space_color: "",
    room_capacity: "",
    devices: [],
  });
  console.log(spaceData, "space");
  const { id } = useParams();
  useEffect(() => {
    fetchFloors();
  }, []);
  useEffect(() => {
    applySpaceColor();
  }, [floors]);
  const fetchFloors = async () => {
    try {
      const token = localStorage.token;
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}admin/floor_details?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.floor;
      setFloor(data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching floors:", error);
    }
  };
  const applySpaceColor = () => {
    if (floors && floors.spaces) {
      floors.spaces.forEach((space) => {
        const pathId = `room${space.path_id}`;
        const pathElement = document.getElementById(pathId);
        const textElement = document.getElementById(
          `space-name${space.path_id}`
        );
        if (pathElement && space.space_color) {
          pathElement.style.fill = space.space_color;
        }
        if (textElement) {
          textElement.textContent = space.space_name;
          textElement.style.display = space.space_color ? "block" : "none";
        }
      });
    }
  };
  const handleClick = (event) => {
    const targetId = event.target.id;
    if (targetId.startsWith("room")) {
      const circleNumber = targetId.replace("room", "");
      setPathID(circleNumber);
      setSpaceModalShow(true);
      const pathElement = document.getElementById(`room${circleNumber}`);
      const spaceElement = document.getElementById(`space-name${circleNumber}`);
      const selectedSpace = floors.spaces.find(
        (space) => space.path_id === circleNumber
      );

      if (selectedSpace) {
        setSpaceData((prevState) => ({
          ...prevState,
          space_name: selectedSpace.space_name,
          space_color: selectedSpace.space_color,
          floor_id: id,
          path_id: circleNumber,
          devices: selectedSpace.devices,
        }));
        if (pathElement) {
          pathElement.style.fill = selectedSpace.space_color;
        }
        if (spaceElement) {
          spaceElement.textContent = selectedSpace.space_name;
          spaceElement.style.display = selectedSpace.space_color
            ? "block"
            : "none";
        }
      } else {
        setSpaceData({
          space_name: "",
          space_color: "",
          floor_id: id,
          path_id: circleNumber,
          devices: [],
        });
      }
    }
  };
  // document.querySelectorAll('[id^="room"]').forEach((circle) => {
  //   circle.addEventListener("click", handleClick);
  // });
  useEffect(() => {
    const rooms = document.querySelectorAll('[id^="room"]');
    rooms.forEach((room) => {
      room.addEventListener("click", handleClick);
    });
    return () => {
      rooms.forEach((room) => {
        room.removeEventListener("click", handleClick);
      });
    };
  }, [floors]);
  const addSpace = async () => {
    setSpinner(true);
    try {
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}admin/floor_space`,
        spaceData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Add Space successfully!");
      setSpinner(false);
      setError(null);
      setSpaceModalShow(false);
      fetchFloors();
    } catch (error) {
      const errorContent = error.response.data.message;
      setError(errorContent);
      setSpinner(false);
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setSpaceData((prevState) => {
        const devices = checked
          ? [...prevState.devices, name]
          : prevState.devices.filter((device) => device !== name);
        return {
          ...prevState,
          devices,
        };
      });
    } else {
      setSpaceData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleColorChange = (color) => {
    setSpaceData((prevState) => ({
      ...prevState,
      space_color: color.hex,
    }));
  };
  return (
    <>
      {" "}
      {spinner && <Loader />}
      <ToastContainer position="top-right" autoClose={700} theme="colored" />
      <div className="common-bg">
        <div className="container-fluid ">
          <div className="common-section floor-detail-section">
            <div className="custom-table-header">
              <NavLink to={`/building/floor/${floors?.building?.id}`}>
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.74307 10.5428L12.8312 1.45473L11.7183 0.341797L1.51721 10.5428L11.3162 20.3418L12.4291 19.2289L3.74307 10.5428Z"
                    fill="#757575"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.131 9.89414L3.25288 9.89409L3.25288 11.468L20.131 11.4681L20.131 9.89414Z"
                    fill="#757575"
                  />
                </svg>
              </NavLink>
              <h1>
                {floors?.building?.building_name || "-"}{" "}
                <span> {floors?.floor_name || "-"}</span>
              </h1>
            </div>
            <div className="svg-container mt-5">
              <div className="svg-wrapper ">
                <div
                  onClick={handleClick}
                  dangerouslySetInnerHTML={{ __html: floors?.svg_code || "-" }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="svg-color-wrapper">
                <div className="svg-color-header">
                  <ul>
                    <li>Group</li>
                    <li>Group Name</li>
                    {/* <li>Reports To</li> */}
                  </ul>
                </div>
                <div className="svg-color-body">
                  <ul>
                    <li>
                      <div className="group-color ceo"></div>
                      #FF98A0
                    </li>
                    <li>CEO & President</li>
                    {/* <li>1000</li> */}
                  </ul>
                  <ul>
                    <li>
                      <div
                        className="group-color marketing
"
                      ></div>
                      #376400
                    </li>
                    <li>Marketing</li>
                    {/* <li>1000</li> */}
                  </ul>
                  <ul>
                    <li>
                      <div
                        className="group-color client
"
                      ></div>
                      #008157
                    </li>
                    <li>Client Success</li>
                    {/* <li>1000</li> */}
                  </ul>
                  <ul>
                    <li>
                      <div
                        className="group-color operations
"
                      ></div>
                      #FFE700
                    </li>
                    <li>Operations</li>
                    {/* <li>1000</li> */}
                  </ul>
                  <ul>
                    <li>
                      <div
                        className="group-color sales
"
                      ></div>
                      #00D3FF
                    </li>
                    <li>Sales</li>
                    {/* <li>1000</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <Modal
        show={spaceModalShow}
        onHide={() => setSpaceModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modal-header pb-0">
          <Button
            className="modal-close-button"
            onClick={() => setSpaceModalShow(false)}
          >
            <img src={ModalCloseBtn} alt="image" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="moda-body-content">
            <div className="modal-content-header">
              <img src={BuildingIconGreen} alt="image" />
              <h1>Add Space Name</h1>
            </div>

            <form>
              {error && (
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="mb-3 modal-form-field">
                    <label>Space Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Space Name"
                      name="space_name"
                      value={spaceData.space_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 modal-form-field">
                    <label>Space Color</label>
                    <div className="picker-field">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Space Color"
                        name="space_color"
                        value={spaceData.space_color}
                        onChange={handleChange}
                      />
                      <button
                        type="button"
                        className="picker-btn"
                        onClick={() => setShowColorPicker(!showColorPicker)}
                      >
                        <img src={PickerIcon} alt="" />
                      </button>
                      {showColorPicker && (
                        <div
                          style={{
                            position: "absolute",
                            zIndex: "2",
                            right: "0px",
                          }}
                        >
                          <div
                            style={{
                              position: "fixed",
                              top: "0px",
                              right: "0px",
                              bottom: "0px",
                              left: "0px",
                            }}
                            onClick={() => setShowColorPicker(false)}
                          />
                          <SketchPicker
                            color={spaceData.space_color}
                            onChangeComplete={handleColorChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 modal-form-field">
                    <label>Floor Name</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={floors?.floor_name || "-"}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 modal-form-field">
                    <label>Room Capacity</label>
                    <input
                      type="text"
                      className="form-control"
                      name="room_capacity"
                      value={spaceData.room_capacity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 modal-form-field ">
                    <label>Install Devices</label>
                    <div className="radio">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="Wifi"
                          checked={spaceData.devices.includes("Wifi")}
                          onChange={handleChange}
                        />
                        <label className="form-check-label">Wifi</label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="Router"
                          checked={spaceData.devices.includes("Router")}
                          onChange={handleChange}
                        />
                        <label className="form-check-label">Router</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 modal-form-field-btn">
                    <button
                      type="button"
                      onClick={() => setSpaceModalShow(false)}
                    >
                      Cancel
                    </button>
                    <button type="button" onClick={addSpace}>
                      Add Space
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FloorSpace;
