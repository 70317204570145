import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = {
  loading: false,
  floorNames: [],
  error: null,
  selectedFloor: null,
};

export const fetchFloorNames = createAsyncThunk(
  "floorName/fetchFloorNames",
  async (buildingId, { rejectWithValue }) => {
    try {
      const token = localStorage.token;
      const response = await axios.get(`${BASE_URL}admin/floors_names`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          building_id: buildingId,
        },
      });
      return response?.data?.data?.floors;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message || "An error occurred"
      );
    }
  }
);

const floorNameSlice = createSlice({
  name: "floorName",
  initialState,
  reducers: {
    setSelectedFloor: (state, action) => {
      state.selectedFloor = action.payload;
    },
    clearFloors: (state) => {
      state.floorNames = [];
    },
    resetFloorState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFloorNames.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFloorNames.fulfilled, (state, action) => {
        state.loading = false;
        state.floorNames = action.payload;
      })
      .addCase(fetchFloorNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { setSelectedFloor, clearFloors, resetFloorState } =
  floorNameSlice.actions;
export default floorNameSlice.reducer;
