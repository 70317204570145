import React from "react";
import { Tooltip } from "react-tooltip";
const monthlyData = [
  {
    day: "Week 1",
    hours: {
      "08:00": 1800,
      "09:00": 2200,
      "10:00": 2500,
      "11:00": 3000,
      "12:00": 2800,
      "13:00": 2000,
      "14:00": 2400,
      "15:00": 2100,
      "16:00": 1900,
      "17:00": 2700,
      "18:00": 3100,
      "19:00": 3300,
      "20:00": 2900,
    },
  },
  {
    day: "Week 2",
    hours: {
      "08:00": 2000,
      "09:00": 2300,
      "10:00": 2600,
      "11:00": 2800,
      "12:00": 3200,
      "13:00": 3000,
      "14:00": 2100,
      "15:00": 2700,
      "16:00": 2500,
      "17:00": 2900,
      "18:00": 3100,
      "19:00": 3400,
      "20:00": 3100,
    },
  },
  {
    day: "Week 3",
    hours: {
      "08:00": 2400,
      "09:00": 2500,
      "10:00": 2800,
      "11:00": 3000,
      "12:00": 3300,
      "13:00": 3500,
      "14:00": 2700,
      "15:00": 2900,
      "16:00": 3100,
      "17:00": 3200,
      "18:00": 3400,
      "19:00": 3500,
      "20:00": 3600,
    },
  },
  {
    day: "Week 4",
    hours: {
      "08:00": 2100,
      "09:00": 2200,
      "10:00": 2500,
      "11:00": 2800,
      "12:00": 3000,
      "13:00": 3200,
      "14:00": 2600,
      "15:00": 2400,
      "16:00": 2700,
      "17:00": 2900,
      "18:00": 3100,
      "19:00": 3300,
      "20:00": 3400,
    },
  },
];

const weeklyData = [
  {
    day: "Mon",
    hours: {
      "08:00": 1800,
      "09:00": 2700,
      "13:00": 2400,
      "14:00": 2400,
      "16:00": 2200,
      "20:00": 2000,
    },
  },
  {
    day: "Tue",
    hours: {
      "11:00": 3200,
      "16:00": 2100,
      "20:00": 1900,
    },
  },
  {
    day: "Wed",
    hours: {
      "14:00": 3200,
    },
  },
  {
    day: "Thu",
    hours: {
      "18:00": 3200,
    },
  },
  {
    day: "Fri",
    hours: {
      "08:00": 2200,
    },
  },
  {
    day: "Sat",
    hours: {
      "08:00": 2200,
      "09:00": 2400,
      "10:00": 2700,
      "11:00": 3200,
    },
  },
  {
    day: "Sun",
    hours: {
      "09:00": 400,
      "10:00": 2700,
      "11:00": 3200,
      "20:00": 2200,
    },
  },
];

const timeData = [
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
];
const findRanks = (hours) => {
  const values = Object.values(hours);
  const max = Math.max(...values);
  const min = Math.min(...values);

  let secondMax = -Infinity;
  let thirdMax = -Infinity;

  values.forEach((value) => {
    if (value > secondMax && value < max) {
      thirdMax = secondMax;
      secondMax = value;
    } else if (value > thirdMax && value < secondMax) {
      thirdMax = value;
    }
  });

  return { max, secondMax, thirdMax, min };
};

const getTrafficClass = (value, ranks) => {
  const { max, secondMax, thirdMax, min } = ranks;

  if (value === max) return "FullyOccupied";
  if (value === secondMax) return "MidLevelOccupied";
  if (value === thirdMax) return "MidFree";
  if (value === min) return "FullFree";
  return "FullFree";
};
const GridChart = ({ view }) => {
  const occupancyData = view === "weekly" ? weeklyData : monthlyData;
  return (
    <div className="map-heat-section common-section-wrapper">
      <div className="heat-map-body">
        <div className="week-days">
          <ul>
            {occupancyData.map((dayData, index) => (
              <li key={index}>{dayData.day}</li>
            ))}
          </ul>
        </div>
        <div className="hourly-grid">
          {timeData.map((hour) => (
            <div className="grid-col" key={hour}>
              {occupancyData.map((dayData, index) => {
                const ranks = findRanks(dayData.hours);
                const value = dayData.hours[hour];

                return (
                  <div
                    className={`grid-box ${getTrafficClass(value, ranks)}`}
                    key={index}
                    data-tooltip-id={`tooltip-${index}-${hour}`}
                  ></div>
                );
              })}
              <div className="grid-box time">{hour}</div>
            </div>
          ))}
        </div>
      </div>

      {occupancyData.map((dayData, dayIndex) =>
        Object.keys(dayData.hours).map((hour, hourIndex) => (
          <Tooltip
            key={`${dayIndex}-${hourIndex}`}
            id={`tooltip-${dayIndex}-${hour}`}
          >
            <div className="tooltip-data">
              <span>{dayData.day}</span>
              <span>{hour}</span>
              <span>{dayData.hours[hour] || "No Data"}</span>
            </div>
          </Tooltip>
        ))
      )}
    </div>
  );
};

export default GridChart;
