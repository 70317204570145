import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const UserDemographic = ({ spaceGraphData }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Occupied Space",
        data: [],
      },
      {
        name: "Requests Space",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          endingShape: "rounded",
          borderRadius: 5,
        },
      },
      colors: ["#63E6BE", "#66D9E8"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100,
        labels: {
          formatter: function (val) {
            return val.toFixed(0) + "K";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        markers: {
          fillColors: ["#63E6BE", "#66D9E8"],
        },
      },
    },
  });

  useEffect(() => {
    const { categories = [], occupied = [], requests = [] } = spaceGraphData;

    setChartData((prevData) => ({
      ...prevData,
      series: [
        {
          name: "Occupied Space",
          data: occupied,
        },
        {
          name: "Requests Space",
          data: requests,
        },
      ],
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: categories,
        },
      },
    }));
  }, [spaceGraphData]);

  return (
    <div className="user-stat-right">
      <div className="chart-wrapper">
        <div className="chart-heading">
          <h1>
            <span>Statistics</span>Demographic
          </h1>
        </div>
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDemographic;
