import React from "react";
import ReactApexChart from "react-apexcharts";
import { NavLink } from "react-router-dom";
import ProfileImg from "../../../assets/images/profile-img.png";
import MailIcon from "../../../assets/images/mail-icon.png";
import PhoneIcon from "../../../assets/images/phone-icon.png";
const Demographic = ({ users, graphData }) => {
  const buildingData = [
    { name: "EM01", value: 100 },
    { name: "EM02", value: 90 },
    { name: "EM03", value: 80 },
    { name: "EM04", value: 80 },
    { name: "EM05", value: 60 },
    { name: "EM06", value: 50 },
    { name: "EM07", value: 40 },
    { name: "EM08", value: 20 },
  ];
  const seriesData = [
    {
      name: "Building Value",
      data: graphData.map((building) => ({
        x: building.name
          .split(" ")
          .map((word) => word.charAt(0))
          .join(""),
        y: building.value,
      })),
    },
  ];
  const options = {
    series: seriesData,
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
      type: "bar",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 5,
      },
    },

    colors: [
      function ({ value, seriesIndex, w }) {
        if (value === 100) {
          return "#D12F2F";
        } else if (value >= 90) {
          return "#D12F2F";
        } else if (value >= 40) {
          return "#FFBF42";
        } else {
          return "#5CA044";
        }
      },
    ],
  };
  return (
    <>
      {" "}
      <div className="row">
        <div className="col-xl-7 col-xxl-8 mb-3">
          <div className="chart-wrapper">
            <div className="chart-heading">
              <h1>
                <span>Statistics</span>Buildings
              </h1>
              <div className="chart-status">
                <h1>
                  <div className="status-box low"></div>Fully Reserved
                </h1>
                <h1>
                  <div className="status-box medium"></div>Average
                </h1>
                <h1>
                  <div className="status-box top"></div>Free
                </h1>
              </div>
            </div>
            <div id="chart">
              <ReactApexChart
                options={options}
                series={options.series}
                type="bar"
                height={280}
              />
            </div>
          </div>

          <div id="html-dist"></div>
        </div>
        <div className="col-xl-5 col-xxl-4 mb-3">
          <div className="user-list-wrapper">
            <div className="user-list-header">
              <h1>List of Users</h1>
              <NavLink to="/user">See All</NavLink>
            </div>
            <div className="user-list-body">
              {users.map((user) => (
                <div className="user-list-card">
                  <div className="user-profile">
                    <img src={user["image"] ?? "-"} alt="img" />
                  </div>
                  <div className="user-profile-content">
                    <div className="user-profile-name">
                      <h1>{user["username"] ?? "-"}</h1>
                    </div>
                    <div className="user-profile-contact">
                      <h1>
                        <img src={MailIcon} alt="img" />
                        {user["email"] ?? "-"}
                      </h1>
                      <h1>
                        <img src={PhoneIcon} alt="img" />
                        {user["phone_number"] ?? "-"}
                      </h1>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Demographic;
