import React from "react";
import ProfileImg from "../../../assets/images/profile-img.png";
import { NavLink } from "react-router-dom";
const FloorBanner = ({ building }) => {
  return (
    <div className="floor-banner">
      <div className="building-img">
        {building.files && building.files.length > 0 && (
          <img src={building.files[0]} alt="Building" />
        )}
      </div>
      <div className="floor-building-content">
        <div className="building-onwership">
          <img src={ProfileImg} alt="" />
          <h1>
            {building.owner?.username ?? "Unknown Owner"}{" "}
            <span>{building.owner?.total_buildings ?? "0"} Buildings</span>
          </h1>
        </div>
        <div className="floor-banner-btn">
          <div className="building-detail">
            <h1>
              {building.building_name}{" "}
              <span>{building.total_floors} Floor</span>
            </h1>
            <NavLink to={`/building/floor/detail/${building.id}`}>
              See Floors Map
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorBanner;
