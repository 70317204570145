import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const WindDetail = () => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = [
        {
          day: "Monday",
          data: [
            { time: "12 AM", utilization: 44 },
            { time: "2 AM", utilization: 55 },
            { time: "4 AM", utilization: 41 },
            { time: "6 AM", utilization: 67 },
            { time: "8 AM", utilization: 22 },
            { time: "10 AM", utilization: 95 },
            { time: "12 PM", utilization: 21 },
            { time: "2 PM", utilization: 49 },
            { time: "4 PM", utilization: 35 },
            { time: "6 PM", utilization: 70 },
            { time: "8 PM", utilization: 51 },
            { time: "10 PM", utilization: 90 },
          ],
        },
        {
          day: "Tuesday",
          data: [
            { time: "12 AM", utilization: 13 },
            { time: "2 AM", utilization: 23 },
            { time: "4 AM", utilization: 20 },
            { time: "6 AM", utilization: 8 },
            { time: "8 AM", utilization: 13 },
            { time: "10 AM", utilization: 27 },
            { time: "12 PM", utilization: 33 },
            { time: "2 PM", utilization: 12 },
            { time: "4 PM", utilization: 43 },
            { time: "6 PM", utilization: 22 },
            { time: "8 PM", utilization: 27 },
            { time: "10 PM", utilization: 15 },
          ],
        },
        {
          day: "Wednesday",
          data: [
            { time: "12 AM", utilization: 20 },
            { time: "2 AM", utilization: 30 },
            { time: "4 AM", utilization: 25 },
            { time: "6 AM", utilization: 15 },
            { time: "8 AM", utilization: 35 },
            { time: "10 AM", utilization: 45 },
            { time: "12 PM", utilization: 50 },
            { time: "2 PM", utilization: 55 },
            { time: "4 PM", utilization: 60 },
            { time: "6 PM", utilization: 70 },
            { time: "8 PM", utilization: 75 },
            { time: "10 PM", utilization: 65 },
          ],
        },
        {
          day: "Thursday",
          data: [
            { time: "12 AM", utilization: 60 },
            { time: "2 AM", utilization: 55 },
            { time: "4 AM", utilization: 50 },
            { time: "6 AM", utilization: 45 },
            { time: "8 AM", utilization: 40 },
            { time: "10 AM", utilization: 35 },
            { time: "12 PM", utilization: 30 },
            { time: "2 PM", utilization: 40 },
            { time: "4 PM", utilization: 50 },
            { time: "6 PM", utilization: 60 },
            { time: "8 PM", utilization: 65 },
            { time: "10 PM", utilization: 70 },
          ],
        },
        {
          day: "Friday",
          data: [
            { time: "12 AM", utilization: 30 },
            { time: "2 AM", utilization: 25 },
            { time: "4 AM", utilization: 20 },
            { time: "6 AM", utilization: 15 },
            { time: "8 AM", utilization: 10 },
            { time: "10 AM", utilization: 20 },
            { time: "12 PM", utilization: 30 },
            { time: "2 PM", utilization: 40 },
            { time: "4 PM", utilization: 50 },
            { time: "6 PM", utilization: 60 },
            { time: "8 PM", utilization: 70 },
            { time: "10 PM", utilization: 80 },
          ],
        },
        {
          day: "Saturday",
          data: [
            { time: "12 AM", utilization: 15 },
            { time: "2 AM", utilization: 20 },
            { time: "4 AM", utilization: 25 },
            { time: "6 AM", utilization: 30 },
            { time: "8 AM", utilization: 35 },
            { time: "10 AM", utilization: 40 },
            { time: "12 PM", utilization: 45 },
            { time: "2 PM", utilization: 50 },
            { time: "4 PM", utilization: 55 },
            { time: "6 PM", utilization: 60 },
            { time: "8 PM", utilization: 65 },
            { time: "10 PM", utilization: 70 },
          ],
        },
        {
          day: "Sunday",
          data: [
            { time: "12 AM", utilization: 70 },
            { time: "2 AM", utilization: 65 },
            { time: "4 AM", utilization: 60 },
            { time: "6 AM", utilization: 55 },
            { time: "8 AM", utilization: 50 },
            { time: "10 AM", utilization: 45 },
            { time: "12 PM", utilization: 40 },
            { time: "2 PM", utilization: 35 },
            { time: "4 PM", utilization: 30 },
            { time: "6 PM", utilization: 25 },
            { time: "8 PM", utilization: 20 },
            { time: "10 PM", utilization: 15 },
          ],
        },
      ];

      setApiData(fetchedData);
    };

    fetchData();
  }, []);
  const timeLabels =
    apiData.length > 0 ? apiData[0].data.map((item) => item.time) : [];

  const colorThresholds = {
    highest: "#9ddff3",
    average: "#de8d35",
    medium: "#1e1e1e",
    free: "#009ce8",
  };

  const getColor = (utilization, maxUtilization, minUtilization) => {
    if (utilization === maxUtilization) return colorThresholds.highest;
    if (utilization >= (maxUtilization + minUtilization) / 2)
      return colorThresholds.medium;
    if (utilization > minUtilization) return colorThresholds.average;
    return colorThresholds.free;
  };

  const chartSeries = apiData.map((dayData) => {
    const utilizations = dayData.data.map((item) => item.utilization);
    const maxUtilization = Math.max(...utilizations);
    const minUtilization = Math.min(...utilizations);

    return {
      name: `${dayData.day} utilization`,
      data: dayData.data.map((item) => ({
        x: item.time,
        y: item.utilization,
        fillColor: getColor(item.utilization, maxUtilization, minUtilization),
      })),
    };
  });

  const generateColors = () => {
    return chartSeries.flatMap((seriesData) =>
      seriesData.data.map((dataPoint) => dataPoint.fillColor)
    );
  };

  const chartData = {
    series: chartSeries,
    options: {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          endingShape: "rounded",
          borderRadius: 5,
        },
      },
      colors: generateColors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: timeLabels,
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
    },
  };

  return (
    <div className="room-usage-insights mt-3">
      <div className="space-analytics-common-wrapper">
        <div className="space-analytics-header">
          <h1>
            <span>Statistics</span> Utilization for the Last 7 Days
          </h1>
        </div>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
        <div className="utilization-legend">
          <div>
            <span
              className="legend-color highest"
              style={{ backgroundColor: "#9ddff3" }}
            ></span>
            Highest Utilization
          </div>
          <div>
            <span
              className="legend-color average"
              style={{ backgroundColor: "#1e1e1e" }}
            ></span>
            Average Utilization
          </div>
          <div>
            <span
              className="legend-color medium"
              style={{ backgroundColor: "#de8d35" }}
            ></span>
            Medium Utilization
          </div>
          <div>
            <span
              className="legend-color free"
              style={{ backgroundColor: "#009ce8" }}
            ></span>
            Free
          </div>
        </div>
      </div>
    </div>
  );
};

export default WindDetail;
