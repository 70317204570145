import React from "react";
import OwnerList from "../../components/admin/owner/ownerList";

const Owner = () => {
  return (
    <>
      <OwnerList />
    </>
  );
};

export default Owner;
