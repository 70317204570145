import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalCloseBtn from "../../../../assets/images/modal-close-btn.png";
import BuildingIconGreen from "../../../../assets/images/building-modal-icon.png";
import AddFile from "./fileUpload/dropZone";

const UpdateBuilding = ({
  show,
  onHide,
  setSpinner,
  toast,
  fetchBuildings,
  building,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [buildingOwners, setBuildingOwners] = useState([]);
  const [oldBuildingImages, setOldBuildingImages] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [error, setError] = useState(null);
  const [imageData, setImageData] = useState([]);
  const [buildingData, setBuildingData] = useState({
    building_name: "",
    location: "",
    descriptions: "",
  });
  useEffect(() => {
    if (!show) {
      setError(null);
    }
  }, [show]);
  useEffect(() => {
    if (building) {
      setBuildingData({
        building_name: building.building_name,
        location: building.location,
        descriptions: building.descriptions,
      });
      setOldBuildingImages(building.files);
      setSelectedOwner(building.owner.id);
    }
  }, [building]);

  const handleShowImageData = (files) => {
    if (files && files.length > 0) {
      setImageData(files);
    }
  };

  useEffect(() => {
    const fetchBuildingOwners = async () => {
      try {
        const token = localStorage.token;
        const response = await axios.get(`${BASE_URL}admin/building-owners`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBuildingOwners(response.data.data.users);
      } catch (error) {
        setError(error.response.data.message);
      }
    };
    fetchBuildingOwners();
  }, [BASE_URL]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBuildingData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const uploadImages = async () => {
    try {
      const formData = new FormData();
      imageData.forEach((file) => {
        formData.append("files[]", file);
      });
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}user/upload_files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      console.error("Error uploading images:", error);
      throw error;
    }
  };

  const handleUpdateBuilding = async () => {
    setSpinner(true);
    try {
      let finalImages = [];
      if (imageData.length > 0) {
        const imageUrls = await uploadImages();
        finalImages = imageUrls.map((image) => image);
        console.log(finalImages, "test");
      }
      finalImages = [
        ...finalImages,
        ...oldBuildingImages.map((image) => image),
      ];
      const token = localStorage.token;
      const response = await axios.post(
        `${BASE_URL}admin/building?id=${building.id}`,
        {
          ...buildingData,
          owner_id: selectedOwner,
          files_data: finalImages,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onHide();
      toast.success("Building updated Successfully");
      fetchBuildings();
      setError(null);
      setSpinner(false);
      setImageData([]);
    } catch (error) {
      setError(error.response.data.message);
      setSpinner(false);
    }
  };

  const handleModalHide = () => {
    onHide();
    setImageData([]);
  };
  const handleRemoveImage = (imagePath) => {
    const updatedOldBuildingImages = oldBuildingImages.filter(
      (image) => image !== imagePath
    );
    setOldBuildingImages(updatedOldBuildingImages);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleModalHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modal-header pb-0">
          <Button className="modal-close-button" onClick={handleModalHide}>
            <img src={ModalCloseBtn} alt="image" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="moda-body-content">
            <div className="modal-content-header">
              <img src={BuildingIconGreen} alt="image" />
              <h1>Update Building</h1>
              <p>Please update the building information below.</p>
            </div>
            <form>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="mb-3 modal-form-field">
                <AddFile onShowImageData={handleShowImageData} />
              </div>
              <div className="old-image-container">
                {oldBuildingImages.map((image, index) => (
                  <div key={index} className="image-wrapper">
                    <img src={image} alt={`Old Image ${index}`} />
                    <button
                      type="button"
                      className="remove-button"
                      onClick={() => handleRemoveImage(image)}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
              <div className="mb-3 modal-form-field">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Building Name"
                  name="building_name"
                  value={buildingData.building_name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 modal-form-field">
                <label>Location</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Location Here"
                  name="location"
                  value={buildingData.location}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 modal-form-field">
                <label>Building Capacity</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Building Capacity in Members"
                  // name="location"
                  // value={buildingData.location}
                  // onChange={handleChange}
                />
              </div>
              <div className="mb-3 modal-form-field">
                <label>Description</label>
                <textarea
                  className="form-control"
                  placeholder="Description Here"
                  name="descriptions"
                  value={buildingData.descriptions}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="mb-3 modal-form-field">
                <label>Building Owner</label>
                <select
                  className="form-select"
                  value={selectedOwner}
                  onChange={(e) => setSelectedOwner(e.target.value)}
                >
                  <option value="">Select owner</option>
                  {buildingOwners.map((owner) => (
                    <option key={owner.id} value={owner.id}>
                      {owner.username}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 modal-form-field-btn">
                <button onClick={handleModalHide}>Cancel</button>
                <button type="button" onClick={handleUpdateBuilding}>
                  Update Building
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateBuilding;
