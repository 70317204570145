import React from "react";
import BuildingImageVertical from "../../../assets/images/landingImages/vertical-building-img.svg";
import FloorIcon from "../../../assets/images/landingImages/floor-number-icon.png";
import { NavLink } from "react-router-dom";
const BuildingList = ({ buildings }) => {
  return (
    <div className="container building-list-section">
      <div className="building-list-header">
        <div className="building-list-heading">
          <h1>
            Buildings List
            <span>Lorem Ipsum is simply dummy text simply dummy text</span>
          </h1>
        </div>
      </div>
      <div className="building-list-body mt-5">
        <div className="__vertical-image mb-3">
          <img src={BuildingImageVertical} alt="img" />
        </div>
        <div className="__card-wrapper">
          <div className="row">
            {buildings.slice(0, 6).map((building) => (
              <div className="col-md-6 col-xl-4 mb-3" key={building.id}>
                <div className="building-card">
                  <div className="building-card-header">
                    <div className="building-img-wrapper">
                      {building.files && building.files.length > 0 && (
                        <img src={building.files[0]} alt="" />
                      )}
                    </div>
                    <div className="building-onwership">
                      <img src={building.owner.image} alt="" />
                      <h1>
                        {building.owner.username}{" "}
                        <span>{building.owner.total_buildings} buildings</span>
                      </h1>
                    </div>
                    <div className="building-detail">
                      <h1>
                        {building.building_name}
                        <span>{building.location}</span>
                      </h1>
                      <h2>
                        <img src={FloorIcon} alt="" />{" "}
                        <span>{building.total_floors}</span> Floors
                      </h2>
                    </div>
                  </div>
                  <div className="building-card-footer">
                    <div className="building-card-footer-top">
                      <NavLink
                        className="detail-btn"
                        to={`/all-building/details/${building.id}`}
                      >
                        View Details{" "}
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.19531 8.27344H15.1953M15.1953 8.27344L8.19531 1.27344M15.1953 8.27344L8.19531 15.2734"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingList;
