import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Tooltip } from "react-tooltip";

const weeklyData = [
  {
    day: "Mon",
    hours: {
      "08:00": 1800,
      "09:00": 2700,
      "13:00": 2400,
      "14:00": 2400,
      "16:00": 2200,
      "20:00": 2000,
    },
  },
  {
    day: "Tue",
    hours: {
      "11:00": 3200,
      "16:00": 2100,
      "20:00": 1900,
    },
  },
  {
    day: "Wed",
    hours: {
      "14:00": 3200,
    },
  },
  {
    day: "Thu",
    hours: {
      "18:00": 3200,
    },
  },
  {
    day: "Fri",
    hours: {
      "08:00": 2200,
    },
  },
  {
    day: "Sat",
    hours: {
      "08:00": 2200,
      "09:00": 2400,
      "10:00": 2700,
      "11:00": 3200,
    },
  },
  {
    day: "Sun",
    hours: {
      "09:00": 400,
      "10:00": 2700,
      "11:00": 3200,
      "20:00": 2200,
    },
  },
];

const timeData = [
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
];
const findRanks = (hours) => {
  const values = Object.values(hours);
  const max = Math.max(...values);
  const min = Math.min(...values);

  let secondMax = -Infinity;
  let thirdMax = -Infinity;

  values.forEach((value) => {
    if (value > secondMax && value < max) {
      thirdMax = secondMax;
      secondMax = value;
    } else if (value > thirdMax && value < secondMax) {
      thirdMax = value;
    }
  });

  return { max, secondMax, thirdMax, min };
};
const getTrafficClass = (value) => {
  return value > 0 ? "occupied" : "free";
};

const RoomGridChart = () => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    const startOfWeek = moment(date).toDate();
    const endOfWeek = moment(date).add(6, "days").toDate();
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };

  const toggleDatePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  const datePickerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const occupancyData = weeklyData;
  return (
    <div className="room-grid-section mt-3">
      {" "}
      <div className="space-analytics-common-wrapper">
        <div className="space-analytics-header">
          <h1>
            <span>Statistics</span>Usage Profile
          </h1>
          <div className="date-wrapper mt-3">
            <p>
              Present {moment(startDate).format("MMMM D")} -{" "}
              {moment(endDate).format("MMMM D")}
            </p>
            <div className="range-picker-wrapper">
              <button className="date-toggle" onClick={toggleDatePicker}>
                Date Range
                <KeyboardArrowDownIcon />
              </button>
              {isPickerOpen && (
                <div className="range-picker" ref={datePickerRef}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsStart
                    inline
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="map-heat-section common-section-wrapper mt-2">
          <div className="heat-map-body">
            <div className="week-days">
              <ul>
                {occupancyData.map((dayData, index) => (
                  <li key={index}>{dayData.day}</li>
                ))}
              </ul>
            </div>
            <div className="hourly-grid">
              {timeData.map((hour) => (
                <div className="grid-col" key={hour}>
                  {occupancyData.map((dayData, index) => {
                    const ranks = findRanks(dayData.hours);
                    const value = dayData.hours[hour];

                    return (
                      <div
                        className={`grid-box ${getTrafficClass(value, ranks)}`}
                        key={index}
                        data-tooltip-id={`tooltip-${index}-${hour}`}
                      ></div>
                    );
                  })}
                  <div className="grid-box time">{hour}</div>
                </div>
              ))}
            </div>
          </div>

          {occupancyData.map((dayData, dayIndex) =>
            Object.keys(dayData.hours).map((hour, hourIndex) => (
              <Tooltip
                key={`${dayIndex}-${hourIndex}`}
                id={`tooltip-${dayIndex}-${hour}`}
              >
                <div className="tooltip-data">
                  <span>{dayData.day}</span>
                  <span>{hour}</span>
                  <span>{dayData.hours[hour] || "No Data"}</span>
                </div>
              </Tooltip>
            ))
          )}
        </div>
        <div className="chart-status mt-2">
          <h1>
            <div className="status-box full-occupied"></div>Fully Occupied
          </h1>

          <h1>
            <div className="status-box full-free"></div>Full Free
          </h1>
        </div>
      </div>
    </div>
  );
};

export default RoomGridChart;
