import React, { useState, useRef, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const OccupancyBarChart = () => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleStartDateChange = (date) => {
    const startOfWeek = moment(date).toDate();
    const endOfWeek = moment(date).add(6, "days").toDate();
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };
  const toggleDatePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };
  const datePickerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const chartData = {
    series: [
      {
        data: [44, 55, 57, 56, 61, 58, 63],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
          borderRadius: 5,
        },
      },
      colors: "#24CCA7",
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        markers: {
          fillColors: ["#4A3AFF", "#C893FD"],
        },
      },
    },
  };
  return (
    <div className="occupancy-chart-section mt-3">
      <div className="utilization-bar-chart-header">
        <div className="graph-heading pb-0">
          <h1 className="m-0">
            <span>Activity</span>
            Building wise max Occupancy
          </h1>
        </div>
        <p>
          Present {moment(startDate).format("MMMM D")} -{" "}
          {moment(endDate).format("MMMM D")}
        </p>
        <div className="range-picker-wrapper">
          <button className="date-toggle" onClick={toggleDatePicker}>
            Date Range
            <KeyboardArrowDownIcon />
          </button>
          {isPickerOpen && (
            <div className="range-picker" ref={datePickerRef}>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsStart
                inline
              />
            </div>
          )}
        </div>
      </div>
      <div className="occupancy-chart-header mt-3">
        <div className="chart-status justify-content-end">
          <h1>
            <div className="status-box free"></div>Building 1 Corporate Center
          </h1>
        </div>
      </div>
      <div className="occupancy-chart-body">
        {" "}
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default OccupancyBarChart;
