import React, { useState } from "react";

const Pagination = ({ totalPageCount, currentPage, onPageChange }) => {
  const [maxPagesToShow] = useState(3);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(currentPage - halfMaxPagesToShow, 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPageCount);

    if (totalPageCount <= maxPagesToShow) {
      startPage = 1;
      endPage = totalPageCount;
    } else if (endPage - startPage < maxPagesToShow - 1) {
      startPage = endPage - maxPagesToShow + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="pagination-controls mt-4">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 11L8 6L13 1M6 11L1 6L6 1"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span> Previous</span>
      </button>
      <div className="pagination-counts">
        {getPageNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            className={pageNumber === currentPage ? "active-page" : ""}
          >
            {pageNumber}
          </button>
        ))}
        <label>...</label>
        <button
          onClick={() => onPageChange(totalPageCount)}
          disabled={currentPage === totalPageCount}
        >
          {totalPageCount}
        </button>
      </div>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPageCount}
      >
        <span> Next</span>
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 11L6 6L1 1M8 11L13 6L8 1"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default Pagination;
