import React, { useState, useRef, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Filters from "../../roomUtilization/filters";
import { useBuildingData } from "../../common/useBuildingData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WingGridChart from "./wingGrid";
import RoomGridChart from "./roomGrid";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
const SpaceAnalytics = () => {
  const {
    buildingNames,
    floorNames,
    roomNames,
    handleBuildingChange,
    handleFloorChange,
    handleRoomChange,
  } = useBuildingData();

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleStartDateChange = (date) => {
    const startOfWeek = moment(date).toDate();
    const endOfWeek = moment(date).add(6, "days").toDate();
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };
  const toggleDatePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };
  const datePickerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [series] = useState([75, 25]);
  const [options] = useState({
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    colors: ["#24CCA7", "#F1F1F1"],
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "16px",
              fontWeight: "bold",
              color: "#000",
              formatter: (value) => "Total",
            },
            value: {
              show: true,
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000",
              formatter: (value) => value,
            },
          },
        },
      },
    },
    labels: ["Unutilization", "Utilization"],
  });
  return (
    <div className="space-analytics-section">
      <Filters
        handleBuildingChange={handleBuildingChange}
        buildingNames={buildingNames}
        handleFloorChange={handleFloorChange}
        floorNames={floorNames}
        handleRoomChange={handleRoomChange}
        roomNames={roomNames}
      />
      <div className="space-analytics-chart-wrapper mt-3">
        <div className="donut-chart-box">
          <div className="graph-heading pb-0">
            <h1 className="m-0">
              <span>Statistics</span>
              Ground Floor Current Utilization
            </h1>
          </div>
          <div className="donut-chart mt-2">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width={300}
            />
          </div>
          <p>
            <AccessTimeIcon className="clock-icon" />
            Updated on 03-August-2023 06:00 AM
          </p>
        </div>
        <div className="space-analytics-statistics">
          <div className="space-analytics-statistics-card">
            <h1>
              <span>Statistics</span>
              Floor Capacity
            </h1>
            <h2>38</h2>
          </div>
          <div className="space-analytics-statistics-card">
            <h1>
              <span>Statistics</span>
              Current Occupancy
            </h1>
            <h2>38</h2>
          </div>
          <div className="space-analytics-statistics-card">
            <h1>
              <span>Statistics</span>
              Unassigned Space
            </h1>
            <h2>38</h2>
          </div>
        </div>
        <div className="space-analytics-common-wrapper">
          <div className="space-analytics-header">
            <h1>
              <span>Community</span>Zone Utilization
            </h1>
            <div className="date-wrapper mt-3">
              <p>
                Present {moment(startDate).format("MMMM D")} -{" "}
                {moment(endDate).format("MMMM D")}
              </p>
              <div className="range-picker-wrapper">
                <button className="date-toggle" onClick={toggleDatePicker}>
                  Date Range
                  <KeyboardArrowDownIcon />
                </button>
                {isPickerOpen && (
                  <div className="range-picker" ref={datePickerRef}>
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsStart
                      inline
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="space-analytics-grid mt-3">
            <WingGridChart />
          </div>
        </div>
      </div>
      <div className="space-analytics-chart-wrapper-two mt-3">
        <div className="space-analytics-common-wrapper">
          <div className="space-analytics-header">
            <h1>
              <span>Community</span>Occupancy Profile
            </h1>
            <div className="date-wrapper mt-3">
              <p>
                Present {moment(startDate).format("MMMM D")} -{" "}
                {moment(endDate).format("MMMM D")}
              </p>
              <div className="range-picker-wrapper">
                <button className="date-toggle" onClick={toggleDatePicker}>
                  Date Range
                  <KeyboardArrowDownIcon />
                </button>
                {isPickerOpen && (
                  <div className="range-picker" ref={datePickerRef}>
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsStart
                      inline
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="space-analytics-grid mt-3">
            <RoomGridChart />
          </div>
          <div className="space-analytics-footer">
            <h1>
              Zone Utilization in %<span>Wk1</span>
            </h1>
            <div className="range-wrapper mt-3">
              <div className="range-card">
                <div className="rang-box zero"></div>
                <label>0</label>
              </div>
              <div className="range-card">
                <div className="rang-box min"></div>
                <label>20</label>
              </div>
              <div className="range-card">
                <div className="rang-box average"></div>
                <label>70</label>
              </div>
              <div className="range-card">
                <div className="rang-box max"></div>
                <label>100</label>
              </div>
            </div>
          </div>
        </div>
        <div className="space-analytics-common-wrapper">
          <div className="space-analytics-header">
            <h1>
              <span>Community</span>Utilization
            </h1>
            <div className="date-wrapper mt-3">
              <p>
                Present {moment(startDate).format("MMMM D")} -{" "}
                {moment(endDate).format("MMMM D")}
              </p>
              <div className="range-picker-wrapper">
                <button className="date-toggle" onClick={toggleDatePicker}>
                  Date Range
                  <KeyboardArrowDownIcon />
                </button>
                {isPickerOpen && (
                  <div className="range-picker" ref={datePickerRef}>
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsStart
                      inline
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="utilization-stats">
            <h1>
              Average Utilization <span>23.50%</span>
            </h1>
            <p>
              <AccessAlarmIcon className="icon" /> Peak Time Utilization
            </p>
          </div>
          <div className="utilization-stats">
            <h1>
              10:00AM - 10:00 AM <span>23.50%</span>
            </h1>
            <p>
              <AccessAlarmIcon className="icon" /> Quiet Time Utilization
            </p>
          </div>
          <div className="utilization-stats">
            <h1>
              Average Utilization <span>23.50%</span>
            </h1>
            <p>
              <AccessAlarmIcon className="icon" /> Peak Time Utilization
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceAnalytics;
