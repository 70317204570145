import { Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/admin/dashboard";
import User from "./pages/admin/user";
import Owner from "./pages/admin/owner";
import Building from "./pages/admin/building";
import Floor from "./pages/admin/floor";
import UserRequestPage from "./pages/admin/userRequestPage";
import PrivacyPolicyPage from "./pages/admin/privacyPolicyPage";
import TermConditionPage from "./pages/admin/termConditionPage";
import BuildingChartPage from "./pages/admin/buildingChartPage";
import FloorDetailPage from "./pages/admin/floorDetailPage";
import FloorSpacePage from "./pages/admin/floorSpacePage";
import RoomUtilizationPage from "./pages/admin/roomUtilizationPage";
import BookingPanel from "./components/admin/floor/floorDetail/bookingPanel";
import BuildingCorporatePage from "./pages/admin/buildingCorporatePage";
import SpacePerformancePage from "./pages/admin/spacePerformancePage";
import FloorLayoutPage from "./pages/admin/floorLayoutPage";
import WingDetail from "./components/admin/wingDetail/wingDetail";

const AdminRoutes = ({ isAuthenticated, isAdmin }) => (
  <>
    <Route
      path="/dashboard"
      element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
    />
    <Route
      path="/user"
      element={isAuthenticated ? <User /> : <Navigate to="/" />}
    />
    <Route
      path="/owner"
      element={isAuthenticated && isAdmin ? <Owner /> : <Navigate to="/" />}
    />
    <Route
      path="/user-request"
      element={isAuthenticated ? <UserRequestPage /> : <Navigate to="/" />}
    />
    <Route
      path="/building"
      element={isAuthenticated ? <Building /> : <Navigate to="/" />}
    />
    <Route
      path="/building/detail/:id?"
      element={isAuthenticated ? <BuildingChartPage /> : <Navigate to="/" />}
    />
    <Route
      path="/building/floor/:id?"
      element={isAuthenticated ? <Floor /> : <Navigate to="/" />}
    />
    <Route
      path="/building/floor/detail/:id?"
      element={isAuthenticated ? <FloorDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/building/floor/space/:id?"
      element={isAuthenticated ? <FloorSpacePage /> : <Navigate to="/" />}
    />
    <Route
      path="/building/floor/request-panel/:id?"
      element={isAuthenticated ? <BookingPanel /> : <Navigate to="/" />}
    />
    <Route
      path="/admin-policy"
      element={isAuthenticated ? <PrivacyPolicyPage /> : <Navigate to="/" />}
    />
    <Route
      path="/admin-condition"
      element={isAuthenticated ? <TermConditionPage /> : <Navigate to="/" />}
    />
    <Route
      path="/room-utilization"
      element={isAuthenticated ? <RoomUtilizationPage /> : <Navigate to="/" />}
    />
    <Route
      path="/building-corporate"
      element={
        isAuthenticated ? <BuildingCorporatePage /> : <Navigate to="/" />
      }
    />
    <Route
      path="/space-performance"
      element={isAuthenticated ? <SpacePerformancePage /> : <Navigate to="/" />}
    />
    <Route
      path="/floor-layout"
      element={isAuthenticated ? <FloorLayoutPage /> : <Navigate to="/" />}
    />
    <Route
      path="/wing"
      element={isAuthenticated ? <WingDetail /> : <Navigate to="/" />}
    />
  </>
);

export default AdminRoutes;
