import React, { useState, useEffect } from "react";
import Banner from "./banner";
import BuildingList from "./buildingList";
import Loader from "../../common/loader/loader";
import axios from "axios";
const BuildingIndex = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [buildings, setBuildings] = useState([]);
  const fetchBuildings = async (query = "") => {
    try {
      setSpinner(true);
      const response = await axios.get(`${BASE_URL}get_all_buildings`, {
        params: {
          page: pageNumber,
          search: query,
        },
      });
      setBuildings(response.data.data.buildings.data);
      setTotalPages(response.data.data.buildings.last_page);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching buildings:", error);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPageNumber(1);
  };
  useEffect(() => {
    fetchBuildings(searchQuery);
  }, [pageNumber, searchQuery]);
  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  return (
    <>
      {spinner && <Loader />}
      <Banner />
      <BuildingList
        buildings={buildings}
        searchQuery={searchQuery}
        onSearchChange={handleSearchChange}
        totalPageCount={totalPageCount}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default BuildingIndex;
