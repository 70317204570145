import React, { useState, useEffect } from "react";
import BuildingBarChart from "./buildingBarChart";
import FloorBarChart from "./floorBarChart";
import { NavLink } from "react-router-dom";
import BuildingDetail from "./buildingDetail";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../common/loader/loader";
import { useSelector,useDispatch } from "react-redux";
import { fetchBuildingGraph } from "../../../../slices/buildingGraphSlice";
const BuildingChart = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [spinner, setSpinner] = useState(false);
  const [building, setBuilding] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    fetchBuildingDetail();
  }, []);
  const fetchBuildingDetail = async () => {
    try {
      const token = localStorage.token;
      setSpinner(true);
      const response = await axios.get(
        `${BASE_URL}admin/building_details?id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.building;
      setBuilding(data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error("Error fetching buildings:", error);
    }
  };
  const dispatch = useDispatch();
  const { buildingGraph,floorGraph, loading, error } = useSelector(
    (state) => state.buildingGraph
  );
  useEffect(() => {
    dispatch(fetchBuildingGraph(id));
  }, [dispatch, id]);
  const isLoading=spinner || loading;
  return (
    <>
      {isLoading && <Loader />}
      <div className="common-bg">
        <div className="container-fluid">
          <div className="common-section">
            <div className="common-main-heading">
              <div className="__main-heading">
                <NavLink to="/building">
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.74307 10.5428L12.8312 1.45473L11.7183 0.341797L1.51721 10.5428L11.3162 20.3418L12.4291 19.2289L3.74307 10.5428Z"
                      fill="#757575"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.131 9.89414L3.25288 9.89409L3.25288 11.468L20.131 11.4681L20.131 9.89414Z"
                      fill="#757575"
                    />
                  </svg>
                </NavLink>
                <h1>Building Chart</h1>
              </div>
            </div>
            <BuildingBarChart buildingGraph={buildingGraph}/>
          </div>

          <FloorBarChart floorGraph={floorGraph} />
          <BuildingDetail building={building} />
        </div>
      </div>
    </>
  );
};

export default BuildingChart;
